import axios from "axios";
import config from "./config";
import firebase from "firebase";

const castServiceHost = config.castServiceHost;

const {auth} = firebase;

export default class CastService {
    static http = axios.create({
        baseURL: castServiceHost,
        timeout: 60000,
    });

    static async registerCastingDevice(messagingToken) {
        return CastService.http.post(`/casting-devices`, {messagingToken});
    }

    static async refreshCastingDeviceCode() {
        return CastService.makeAuthorizedRequest("get", `/casting-devices/_refresh_code`);
    }

    static async getCastingDeviceSessionToken(sessionID, sessionUserID) {
        return CastService.makeAuthorizedRequest("get", `/casting-devices/sessions/${sessionID}/session-token?sessionUserID=${sessionUserID}`);
    }

    static async requestCastVideoCallAccess(consultationID, videoRoomSID) {
        return CastService.makeAuthorizedRequest("get", `/casting-devices/video-access?consultationID=${consultationID}&videoRoomSID=${videoRoomSID}`);
    }

    static async makeAuthorizedRequest(method, uri, data) {
        const {currentUser} = auth();

        const userToken = await currentUser.getIdToken(true);

        const headers = {
            "Authorization": userToken
        };

        switch(method) {
            case "get":
                return CastService.http.get(uri, {headers});
            case "post":
                return CastService.http.post(uri, data, {headers});
            case "put":
                return CastService.http.post(uri, data, {headers});
            case "delete":
                return CastService.http.post(uri, data, {headers});
            default:
                console.warn(`cannot make authorized request: unknown method ${method}`);
                return;
        }
    }
}
