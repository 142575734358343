import React from "react";
import _ from "lodash";
import firebase from "firebase";
import ProfilePhoto from "./ProfilePhoto";
import {hdFonts} from "../config/fonts";
import {hdColors} from "../config/colors";

const {firestore} = firebase;

export default function RemoteUserTouchable(props) {
    const remoteUserProfileRef = React.useRef({});

    const [profile, setProfile] = React.useState(remoteUserProfileRef.current);

    React.useEffect(() => {
        if (_.isEmpty(profile)) {
            const remoteUserID = localStorage.getItem("remoteUserID");

            firestore().doc(`profiles/${remoteUserID}`).get().then(snapshot => {
                remoteUserProfileRef.current = {
                    id: snapshot.id,
                    ...snapshot.data()
                };

                setProfile(remoteUserProfileRef.current);
            })
        }
    });

    return (
        <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
            <ProfilePhoto profile={profile} size={42}/>
            <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start", marginLeft: 6}}>
                <span style={{...hdFonts.textSemiBold, fontSize: 20, color: hdColors.secondaryDarkest}}>{profile.displayName}</span>
            </div>
        </div>
    )
}
