import _ from "lodash";
import React from "react";
import {hdColors} from "../config/colors";
import {hdFonts} from "../config/fonts";
import TouchableOpacity from "./TouchableOpacity";
import View from "./View";
import Text from "./Text";

const colors = [hdColors.goodDark, hdColors.negativeLight, hdColors.secondary, hdColors.secondaryLightComplement, hdColors.negative, hdColors.lightHeaderBackground, hdColors.darkAccentColor, hdColors.primaryLight, hdColors.examDark];
const userColors = {};

const getUserColor = userUID => {
    if (!userColors[userUID]) {
        userColors[userUID] = colors[_.random(_.size(colors) - 1)];
    }

    return userColors[userUID];
};

export default function ProfilePhoto(props) {
    const {profile} = props;
    const [photoURI, setPhotoURI] = React.useState(profile?.profilePhotoURL);

    const Container = props.onClick ? TouchableOpacity : View;

    React.useEffect(() => {
        if (photoURI !== props.profile?.profilePhotoURL) {
            setPhotoURI(props.profile?.profilePhotoURL);
        }
    });

    const size = props.size || 64;

    const canRenderPhoto = !_.isEmpty(photoURI);

    if (!canRenderPhoto && !_.isEmpty(profile?.displayName)) {
        const firstLetter = _.first(profile.displayName?.split(""));
        const backgroundColor = getUserColor(profile.displayName);

        const fontSize = size / 2;

        return (
            <Container onClick={props.onClick} style={{height: size, width: size, borderRadius: size, backgroundColor, alignItems: "center", justifyContent: "center", ...props.style}}>
                <Text style={{fontFamily: hdFonts.textBold, fontSize, color: "white"}}>{firstLetter}</Text>
            </Container>
        )
    }

    const shadowStyle = !props.borderWidth ? {} : {
        shadowOffset: {height: 0, width: 0},
        shadowRadius: 1,
        shadowColor: props.borderColor,
        shadowOpacity: 0.3
    };

    return (
        <Container onClick={props.onClick} style={{...shadowStyle, ...props.style}}>
            <img src={props.profile.profilePhotoURL} style={{backgroundColor: hdColors.secondaryLight, height: size, width: size, borderRadius: size, borderWidth: 0.5, borderColor: hdColors.secondaryDarkest}} />
        </Container>
    )
};
