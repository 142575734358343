import React from "react";
import {registerSetDataResponder} from "../sessions/messaging";
import {hdColors} from "../config/colors";
import * as twilio from "twilio-video";
import HDCastHeader from "../components/HDCastHeader";
import HDCastControls from "../components/HDCastControls";
import ExaminationHomeView from "../views/ExaminationHomeView";
import {useLocation} from "@reach/router";
import CastService from "../../service";

export const consultationPageHandle = {
    consultationData: {},
    room: null
}

export default function VideoCallPage(props) {
    const location = useLocation();
    const {consultationID, videoRoomSID} = location.state;

    const [consultationData, setConsultationData] = React.useState(consultationPageHandle.consultationData);
    const [videoConnectionStatus, setVideoConnectionStatus] = React.useState("disconnected");

    const videoConnectionStatusRef = React.useRef(videoConnectionStatus);


    const doConnect = async () => {
        console.debug("trying connect", {consultationID, videoRoomSID});

        const response = await CastService.requestCastVideoCallAccess(consultationID, videoRoomSID);
        const {token} = response.data;
        console.debug("got token", token);
        const connectionOptions = {
            name: videoRoomSID,
            audio: true,
            video: {
                width: 480
            }
        };

        twilio.connect(token, connectionOptions).then(room => {
            console.log(`Successfully joined a Room: ${room}`);

            consultationPageHandle.room = room;

            twilio.createLocalVideoTrack().then(track => {
                const localMediaContainer = document.getElementById("local-video-container");
                localMediaContainer.appendChild(track.attach());
            });

            const remoteUserID = localStorage.getItem("remoteUserID");

            room.participants.forEach(participant => {
                if (participant.identity === remoteUserID) {
                    console.log(`not rendering remote user ${remoteUserID}`);
                    return;
                }

                participant.tracks.forEach(publication => {
                    if (publication.track) {
                        document.getElementById("patient-video-container").appendChild(publication.track.attach());
                    }
                });

                participant.on("trackSubscribed", track => {
                    document.getElementById("patient-video-container").appendChild(track.attach());
                });
            });

            room.on("participantConnected", participant => {
                console.log(`A remote Participant connected: ${participant}`);

                if (participant.identity === remoteUserID) {
                    console.log(`not rendering remote user ${remoteUserID}`);
                    return;
                }

                participant.tracks.forEach(publication => {
                    if (publication.isSubscribed) {
                        const track = publication.track;
                        document.getElementById("patient-video-container").appendChild(track.attach());
                    }
                });

                participant.on("trackSubscribed", track => {
                    document.getElementById("patient-video-container").appendChild(track.attach());
                });
            });
        }, error => {
            console.error(`Unable to connect to Room: ${error.message}`);
        });
    }

    React.useEffect(() => {
        doConnect().catch(error => console.warn(`[VideoConsultationPage:doSetConsultationData] error doing connect: ${error}`))


        return () => {
            const {room} = consultationPageHandle;

            if (room) {
                room.disconnect();
            }
        }
    }, []);

    const LocalVideoContainer = () => {
        return (
            <div style={{display: "flex", height: 128, maxHeight: 128, flexDirection: "column", alignItems: "center", justifyContent: "center", backgroundColor: hdColors.secondaryLightest, borderRadius: 4}}>
                <div id={"local-video-container"} style={{display: "flex", maxHeight: "100%", maxWidth: "100%"}}/>
            </div>
        )
    }

    const PatientVideoContainer = props => {
        return (
            <div style={{display: "flex", flex: 1, maxHeight: "100%", alignItems: "center", justifyContent: "center", backgroundColor: "black", borderRadius: 4}}>
                <div id={"patient-video-container"} style={{display: "flex", height: "60vh", maxHeight: "60vh", border: "0px blue solid"}}/>
            </div>
        )
    }

    return (
        <div style={{display: "flex", flexDirection: "column", maxHeight: "100vh", minHeight: "100vh", backgroundColor: hdColors.secondaryLightest}}>
            <HDCastHeader consultation={consultationData}/>
            <div style={{display: "flex", flex: 1, flexDirection: "row"}}>
                <div style={{display: "flex", flexDirection: "column"}}>
                    <ExaminationHomeView consultationData={consultationData}/>
                    <HDCastControls consultation={consultationData}/>
                </div>
                <div className={"video-call-container"}>
                    <div style={{display: "flex", flex: 1, justifyContent: "center", backgroundColor: hdColors.secondaryDarkest, maxHeight: "100%"}}>
                        <PatientVideoContainer/>
                    </div>
                    <div className={"local-video-container"}>
                        <LocalVideoContainer/>
                    </div>
                </div>
            </div>
        </div>
    );
}
