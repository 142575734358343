import moment from "moment";
import React from "react";
import {registerSetDataResponder, removeResponderHandler, sendCastingSessionMessage} from "./sessions/messaging";
import {hdFonts} from "./config/fonts";
import {hdColors} from "./config/colors";
import CircularView from "./components/CircularView";


export default function GynecologyExamPage() {
    const [gynecologyExam, setGynecologyExam] = React.useState({});

    React.useEffect(() => {
        registerSetDataResponder("currentExamHandle", setGynecologyExam);

        return () => {
            removeResponderHandler(setGynecologyExam);
        }
    }, []);

    const ExamHeader = () => {
        const ExamIcon = () => {
            return (
                <div style={{display: "flex", height: 64, width: 64, borderRadius: 64, backgroundColor: hdColors.examLight, alignItems: "center", justifyContent: "center"}}>
                    <i className={`fas fa-clipboard-list`} style={{fontSize: 24, color: "white"}}/>
                </div>
            )
        }

        return (
            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", paddingLeft: 12, paddingRight: 12, width: "100vh"}}>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <ExamIcon/>
                </div>
                <div style={{display: "flex", flexDirection: "column", alignItems: "flex-end"}}>
                    <span style={{...hdFonts.text, fontSize: 32, color: "white", marginLeft: 12}}>Ginecología</span>
                </div>
            </div>
        )
    }

    const doSetGynecologyFieldData = (field, data) => {
        const message = {
            type: "setData",
            field: `gynecologyExam:${field}`,
            data
        }

        sendCastingSessionMessage(message)
            .catch(error => console.warn("[GynecologyExamPage.js:doSetGynecologyFieldData] error sending casting session message", error));
    }

    const LastPeriodDateInput = () => {
        const [lastPeriodDate, setLastPeriodDate] = React.useState(gynecologyExam.lastPeriodDate);

        const doSetLastPeriodDate = event => {
            const {value: lastPeriodDate} = event.target;
            console.log(`setting lastPeriodDate: ${lastPeriodDate}`);
            gynecologyExam.lastPeriodDate = moment(lastPeriodDate);

            setLastPeriodDate(gynecologyExam.lastPeriodDate);

            doSetGynecologyFieldData("lastPeriodDate", gynecologyExam.lastPeriodDate.toDate());
        }

        const doPressAction = event => {
            event.preventDefault();

            const message = {
                type: "doPress",
                pressable: "gynecologyExam:lastPeriodDate"
            }

            sendCastingSessionMessage(message)
                .catch(error => console.warn("[HomePage.js:PadecimientoInput:doPressAction] error sending casting session message", error));
        }
        const sanitizedLastPeriodDate = !lastPeriodDate ? null :  lastPeriodDate.toDate ? moment(lastPeriodDate.toDate()).format("YYYY-MM-DD") : moment(lastPeriodDate).format("YYYY-MM-DD");
        console.debug("lastPeriodDate", lastPeriodDate);

        return (
            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                <CircularView onClick={doPressAction} size={64} color={hdColors.examLight}>
                    <i className="fas fa-clipboard" style={{fontSize: 24}}/>
                </CircularView>
                <a onClick={doPressAction} style={{...hdFonts.text, fontSize: 24, color: "white"}}>Fecha de última regla</a>
                <input type={"date"} value={sanitizedLastPeriodDate} onChange={doSetLastPeriodDate}/>
            </div>
        )
    }

    const GynecologyFieldInput = props => {
        const {field, label} = props;

        const [value, setValue] = React.useState(gynecologyExam[field]);

        const doSetValue = event => setValue(event.target.value);

        const doSetData = event => {
            event.preventDefault();

            doSetGynecologyFieldData(field, value);
        };

        const doPressAction = event => {
            event.preventDefault();

            const message = {
                type: "doPress",
                pressable: `gynecologyExam:${field}`
            }

            sendCastingSessionMessage(message)
                .catch(error => console.warn("[HomePage.js:GynecologyFieldInput:doPressAction] error sending casting session message", error));
        }

        return (
            <form onSubmit={doSetData} style={{paddingTop: 24, paddingBottom: 24}}>
                <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <CircularView onClick={doPressAction} size={64} color={hdColors.examLight}>
                        <i className="fas fa-clipboard" style={{fontSize: 24}}/>
                    </CircularView>
                    <div style={{...hdFonts.textSemiBold, fontSize: 16, color: "white"}}>{label}</div>
                    <input type={"text"} value={value} onChange={doSetValue} style={{width: "50vh", minHeight: "5vh"}}/>
                </div>
            </form>
        )
    }

    return (
        <div style={{display: "flex", flexDirection: "column", alignItems: "center", minHeight: "100vh", flex: 1, backgroundColor: hdColors.examDark, justifyContent: "center", paddingTop: 12}}>
            <ExamHeader/>
            <div style={{flex: 1, alignItems: "center", paddingTop: 36}}>
                <LastPeriodDateInput/>
                <GynecologyFieldInput field={"neckReview"} label={"Cuello"}/>
                <GynecologyFieldInput field={"breastReview"} label={"Mamas"}/>
                <GynecologyFieldInput field={"abdomen"} label={"Abdomen"}/>
                <GynecologyFieldInput field={"vulva"} label={"Vulva"}/>
                <GynecologyFieldInput field={"regionPerianal"} label={"Región perianal"}/>
                <GynecologyFieldInput field={"tactoVaginal"} label={"Tacto vaginal"}/>
                <GynecologyFieldInput field={"cervix"} label={"Cervix"}/>
                <GynecologyFieldInput field={"ultrasonidoUtero"} label={"Ultrasonido: Utero"}/>
                <GynecologyFieldInput field={"ultrasonidoEndometrio"} label={"Ultrasonido: Endometrio"}/>
                <GynecologyFieldInput field={"ultrasonidoOvarios"} label={"Ultrasonido: Ovarios"}/>
            </div>
        </div>
    );
}
