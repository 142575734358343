import React from "react";
import firebase from "firebase"
import _ from "lodash";
import moment from "moment";

import {sendCastingSessionMessage} from "../sessions/messaging";
import {alpha, hdColors} from "../config/colors";
import View from "../components/View";
import Text from "../components/Text";
import {hdFonts} from "../config/fonts";
import HDCastHeader from "../components/HDCastHeader";
import {createChatSessionKeyPair, decryptChatMessage, decryptChatSecretKey, encryptChatMessage} from "../sessions/chat";
import TouchableOpacity from "../components/TouchableOpacity";
import ProfilePhoto from "../components/ProfilePhoto";
import Icon from "@mdi/react";
import {mdiSend} from "@mdi/js";
import Api from "../services/Api";
import {navigate} from "@reach/router";

const {auth, firestore} = firebase;

export const chatPageHandle = {
    chatData: {},
    room: null
}

export default function ChatPage(props) {
    const [remoteUserProfile, setRemoteUserProfile] = React.useState({});
    const [currentChatID, setCurrentChatID] = React.useState(null);
    const [currentChatData, setCurrentChatData] = React.useState(null);
    const [chatPreviews, setChatPreviews] = React.useState([]);
    const [hasError, setHasError] = React.useState(false);

    const newMessageTextRef = React.useRef("");
    const chatMessagesRef = React.useRef([]);
    const chatPreviewsRef = React.useRef(chatPreviews);

    const {currentUser} = auth();

    const doRequestChatKeys = () => {
        const message = {
            type: "requestData",
            field: "chatKeys"
        }

        sendCastingSessionMessage(message)
            .catch(error => console.warn("[ChatPage.js:doGetChatPreviews] error sending casting session message", error));
    }

    const tryCreateChatSessionKeyPair = async () => {
        const {claims: tokenClaims} = await currentUser.getIdTokenResult(true);

        const userSessionSnapshot = await firestore()
            .doc(`users/${currentUser.uid}/castingSessions/${tokenClaims.sessionID}`)
            .get();

        if (!userSessionSnapshot.exists) {
            console.warn(`no token session found, logging out`);

            auth().signOut().finally(() => navigate("/"));
        }

        const userSession = userSessionSnapshot.data();

        if (userSession.publicKey && userSession.castingDevice.id === tokenClaims.deviceID) {
            console.info("not creating chat session key pair: already have key pair for this device");
            return;
        }

        return createChatSessionKeyPair()
            .then(doRequestChatKeys)
            .catch(error => console.warn(`error creating chat session keypair: ${error}`));
    }

    const doGetChatPreviews = () => firestore()
        .collection(`users/${currentUser.uid}/chatPreviews`)
        .onSnapshot(async chatPreviewsSnapshot => {
            const unregisteredChatPreviewSnapshots = chatPreviewsSnapshot.docs.filter(snapshot => !_.some(chatPreviewsRef.current, p => p.id === snapshot.id));

            if (_.isEmpty(unregisteredChatPreviewSnapshots)) {
                console.info("not handling chat previews snapshot: already have all previews registered");
                return;
            }

            const chatPreviewsPromises = chatPreviewsSnapshot.docs.map(async snapshot => {
                const chatPreview = snapshot.data();
                chatPreview.id = snapshot.id;

                const patientProfileSnapshot = await firestore().doc(`profiles/${chatPreview.patient.id}`).get();
                const patientProfile = patientProfileSnapshot.data();
                patientProfile.id = patientProfileSnapshot.id;

                chatPreview.patientProfile = patientProfile;

                return chatPreview;
            });

            const newChatPreviews = await Promise.all(chatPreviewsPromises);
            const sortedChatPreviews = _.sortBy(newChatPreviews, p => p.lastMessage?.sentTime.toDate()).reverse();

            setChatPreviews(sortedChatPreviews);
            chatPreviewsRef.current = sortedChatPreviews;

            const currentChatID = localStorage.getItem(`currentChatID`);

            if (_.isEmpty(currentChatID)) {
                setCurrentChatID(currentChatID);
            }
        })

    const getSessionUserProfile = () => {
        if (!currentUser) {
            console.warn("no current user, what are you doing in ChatPage!");
            return navigate("/");
        }

        return firestore()
            .doc(`profiles/${currentUser.uid}`)
            .get()
            .then(snapshot => setRemoteUserProfile(snapshot.data()));
    }

    const chatPreviewsSnapshotListenerRef = React.useRef(null);

    React.useEffect(() => {
        getSessionUserProfile().catch(error => console.warn("error getting remote user profile", error));

        tryCreateChatSessionKeyPair()
            .then(doGetChatPreviews)
            .then(listener => chatPreviewsSnapshotListenerRef.current = listener)
            .catch(error => {
                console.warn(error);

                setHasError(true);
            });

        return () => {
            if (_.isFunction(chatPreviewsSnapshotListenerRef.current)) {
                chatPreviewsSnapshotListenerRef.current()
            }
        }
    }, []);

    const chatTitle = currentChatData?.chatName || "Chat";

    const ChatPreview = props => {
        const [chatPreview, setChatPreview] = React.useState(props.preview);

        const doRegisterPreviewSnapshotListener = () => firestore()
            .doc(`users/${currentUser.uid}/chatPreviews/${chatPreview.id}`)
            .onSnapshot(async chatPreviewSnapshot => {
                const chatPreview = chatPreviewSnapshot.data();
                chatPreview.id = chatPreviewSnapshot.id;

                const patientProfileSnapshot = await firestore().doc(`profiles/${chatPreview.patient.id}`).get();
                const patientProfile = patientProfileSnapshot.data();
                patientProfile.id = patientProfileSnapshot.id;

                chatPreview.patientProfile = patientProfile;

                setChatPreview(chatPreview);
            })

        React.useEffect(() => {
            const snapshotListener = doRegisterPreviewSnapshotListener();

            return () => {
                snapshotListener();
            }
        }, []);

        const doOpenChat = () => {
            if (isCurrentChat) return null;

            setCurrentChatID(chatPreview.id);
            setCurrentChatData(chatPreview);

            localStorage.setItem(`currentChatID`, chatPreview.id);
        }

        const isCurrentChat = currentChatData?.id === chatPreview.id;
        const backgroundColor = isCurrentChat ? alpha(hdColors.secondaryDarkest, 0.2) : "transparent";

        const formattedLastMessageTime = chatPreview.lastMessage ? moment(chatPreview.lastMessage.sentTime.toDate()).fromNow() : "Nuevo";

        return (
            <TouchableOpacity onClick={doOpenChat} style={{flexDirection: "row", padding: 6, paddingTop: 12, paddingBottom: 12, backgroundColor}}>
                <View style={{flex: 1, flexDirection: "row", alignItems: "center"}}>
                    <ProfilePhoto profile={chatPreview.patientProfile} size={28}/>
                    <Text style={{...hdFonts.regular, fontSize: 18, color: hdColors.secondaryDarkest, marginLeft: 12}}>{chatPreview.chatName}</Text>
                </View>
                <Text style={{...hdFonts.regular, fontSize: 14, color: alpha(hdColors.secondaryDarkest, 0.8)}}>{formattedLastMessageTime}</Text>
            </TouchableOpacity>
        )
    }

    const ChatMessage = props => {
        const {message, index} = props;
        if (message.contentType !== "text/plain") {
            return null;
        }
        const isRemoteUserSender = message.sender?.id === currentUser.uid;
        const flexDirection = isRemoteUserSender ? "row-reverse" : "row";
        const senderProfile = isRemoteUserSender ? remoteUserProfile : currentChatData.patientProfile;
        const textAlign = isRemoteUserSender ? "right" : "left";

        const shouldShowProfilePhoto = index === 0 || chatMessagesRef.current[index - 1].sender?.id !== message.sender?.id;

        const marginTop = shouldShowProfilePhoto ? 24 : 0;

        const Separator = () => {
            if (!shouldShowProfilePhoto) return null;

            return <ProfilePhoto profile={senderProfile} size={24}/>;
        }
        return (
            <View style={{flexDirection, alignItems: "flex-start", marginTop}}>
                <View style={{width: 42, alignItems: "center"}}>
                    <Separator/>
                </View>
                <Text style={{...hdFonts.regular, fontSize: 18, color: hdColors.secondaryDarkest, maxWidth: "80%", textAlign}}>{message.messageContent}</Text>
            </View>
        )
    }

    const CurrentChatView = () => {
        const [lineLength, setLineLength] = React.useState(54);
        const [newMessageText, setNewMessageText] = React.useState(newMessageTextRef.current);
        const [isSendingMessage, setIsSendingMessage] = React.useState(false);
        const [chatMessages, setChatMessages] = React.useState([]);

        const doSetChatMessages = chatMessages => {
            chatMessagesRef.current = chatMessages;
            setChatMessages(chatMessages);
        }

        const chatMessagesSnapshotListenerRef = React.useRef({});
        const inputRef = React.useRef(null);

        const doGetCurrentChatData = async () => {
            const tokenResult = await currentUser.getIdTokenResult(true);

            const chatSnapshot = await firestore().doc(`chats/${currentChatID}`).get();
            const chat = chatSnapshot.data();
            chat.id = chatSnapshot.id;
            chat.ref = chatSnapshot.ref;

            const deviceChatSecretKey = chat[`device-key-${tokenResult.claims.deviceID}`]

            if (!deviceChatSecretKey) {
                console.warn("no chat key, requesting now");
                await doRequestChatKeys();

                return;
            }

            return decryptChatSecretKey(currentChatID, deviceChatSecretKey)
                .catch(error => console.warn("ERROR DECRYPTING", error))
                .then(() => {
                    chatMessagesSnapshotListenerRef.current = chat.ref.collection("messages").onSnapshot(async chatMessagesSnapshot => {
                        const chatMessagesPromises = chatMessagesSnapshot.docs.map(snapshot => {
                            const chatMessage = snapshot.data();
                            chatMessage.id = snapshot.id;

                            return new Promise(async (resolve, reject) => {
                                if (chatMessage.encryptedMessageContent) {
                                    chatMessage.messageContent = await decryptChatMessage(currentChatID, chatMessage.encryptedMessageContent);
                                }

                                resolve(chatMessage);
                            })
                        });

                        await Promise.all(chatMessagesPromises)
                            .then(chatMessages => _.sortBy(chatMessages, m => m.sentTime.toDate()))
                            .then(doSetChatMessages)
                    })
                });
        }

        React.useLayoutEffect(() => {
            if (inputRef.current) {
                const inputWidthStr = window.getComputedStyle(inputRef.current).width
                const inputWidth = parseFloat(inputWidthStr.replace("px", ""));
                setLineLength(Math.ceil(inputWidth / 11));
            }
        }, []);

        React.useEffect(() => {
            if (!currentChatID) {
                console.info("not getting chat data: no current chat");
                return;
            }

             doGetCurrentChatData().catch(error => console.warn("error getting current chat data on previews load", error));

            return () => {
                if(chatMessagesSnapshotListenerRef.current) {
                    chatMessagesSnapshotListenerRef.current();
                };
            }
        }, [currentChatID])

        React.useEffect(() => {
            newMessageTextRef.current = newMessageText;
        }, [newMessageText])

        if (!currentChatData) {
            return <View style={{flex: 1, backgroundColor: hdColors.secondaryDarkest}}/>
        }

        const doSetNewMessageText = event => setNewMessageText(event.target.value);

        const doSendNewMessage = async () => {
            if (_.isEmpty(_.trim(newMessageText))) {
                return;
            }

            const placeholderMessage = {
                id: `placeholder.${Math.random()}`,
                sender: {id: currentUser.uid},
                messageContent: newMessageText
            }

            chatMessages.push(placeholderMessage);

            setIsSendingMessage(true);
            setNewMessageText("");

            const messageData = {
                senderID: currentUser.uid,
                messageType: "chatTextMessage",
                contentType: "text/plain",
            };

            const byteLengthPaddedMessageContent = _.padStart(newMessageText, 9);

            const chatID = currentChatData.id;

            messageData.encryptedMessageContent = await encryptChatMessage(chatID, byteLengthPaddedMessageContent);

            setIsSendingMessage(false);

            return Api.post(`/messaging/chats/${chatID}/messages`, messageData).catch(error => console.warn("error sending message", error));
        }

        const lineHeight = 24;

        const linesGuess = Math.ceil(_.size(newMessageText) / lineLength) + _.size(newMessageText.split("").filter(t => t === "\n"));
        const textInputHeight = Math.max(lineHeight, linesGuess * lineHeight);

        const handleOnKeyPress = e => {
            if (e.key === "Enter" && !e.shiftKey) {
                doSendNewMessage().catch(console.warn);
            } else if (e.key === "Enter" && e.shiftKey) {
                setNewMessageText(`${newMessageText}\n`);
            }
        }

        return (
            <View style={{flex: 1, justifyContent: "flex-end", width: "100%", backgroundColor: "white", margin: 4, marginBottom: 0, marginRight: 0, borderRadius: 4, borderBottomLeftRadius: 0, overflow: "scroll"}}>
                <View style={{padding: 12, overflow: "scroll"}}>
                    {chatMessages.map((m, i) => <ChatMessage key={m.id} message={m} index={i}/>)}
                </View>
                <View style={{flexDirection: "row", alignItems: "center", padding: 6, backgroundColor: hdColors.secondaryDarkest}}>
                    <View style={{flex: 1, flexDirection: "row", backgroundColor: "white", borderRadius: 24, marginLeft: 12, paddingTop: 6, paddingBottom: 6, alignItems: "center"}}>
                        <textarea
                            ref={ref => inputRef.current = ref}
                            autoFocus={!_.isEmpty(newMessageText)}
                            placeholder={"Escribe tu mensaje..."}
                            disabled={isSendingMessage}
                            cols={lineLength}
                            value={newMessageText}
                            onChange={doSetNewMessageText}
                            onKeyPress={handleOnKeyPress}
                            style={{
                                width: "100%",
                                height: textInputHeight,
                                justifyContent: "center",
                                outline: "none",
                                resize: "none",
                                border: "none",
                                borderRadius: 24,
                                paddingLeft: 12,
                                paddingRight: 12,
                                ...hdFonts.regular,
                                fontSize: 18,
                                color: hdColors.secondaryDarkest}}/>
                    </View>
                    <TouchableOpacity onClick={doSendNewMessage} style={{marginLeft: 12, marginRight: 12, alignItems: "center", opacity: _.isEmpty(newMessageText) ? 0.2 : 1}}>
                        <Icon path={mdiSend} size={1} color={"white"}/>
                    </TouchableOpacity>
                </View>
            </View>
        )
    }

    return (
        <View style={{maxHeight: "100vh", height: "100vh", backgroundColor: hdColors.secondaryLightest}}>
            <HDCastHeader>
                <View style={{flex: 1, alignItems: "flex-end"}}>
                    <Text style={{...hdFonts.extraThin, fontSize: 32, textAlign: "right"}}>{chatTitle}</Text>
                </View>
            </HDCastHeader>
            <View style={{flexDirection: "row", flexWrap: "wrap", height: "100%", overflowY: "auto", overflowX: "hidden"}}>
                <View style={{width: "30%", minWidth: 256, marginTop: 4}}>
                    {chatPreviews.map(p => <ChatPreview key={p.chat.id} preview={p}/>)}
                </View>
                <View style={{flex: 1, flexBasis: "50vw", height: "100%"}}>
                    <CurrentChatView/>
                </View>
            </View>
        </View>
    );
}
