import React from "react";
import firebase from "firebase";
import "animate.css/animate.css";
import {hdFonts} from "./config/fonts";
import {hdColors} from "./config/colors";
import TouchableOpacity from "./components/TouchableOpacity";

const {auth} = firebase;

export default function HomePage() {
    const [castingDeviceCode, setCastingDeviceCode] = React.useState(null);

    const tryGetDeviceCode = async () => {
        const {currentUser} = auth();

        if (!currentUser) {
            console.debug("can't get device code: no current user");
            setCastingDeviceCode(null);
            return;
        }

        const {claims} = await currentUser.getIdTokenResult(true);
        console.debug("got claims", claims);
        setCastingDeviceCode(claims.deviceCode);
    }

    React.useEffect(() => {
        const authStateListener = auth().onAuthStateChanged(tryGetDeviceCode);

        return () => {
            authStateListener();
        }
    }, []);

    return (
        <div className="App" style={{backgroundColor: hdColors.secondaryDarkest}}>
            <header className="App-header" style={{flexDirection: "row"}}>
                <div style={{flex: 1, alignItems: "center"}}>
                    <i className="fas fa-satellite-dish" style={{fontSize: 72}}/>
                    <p style={{...hdFonts.header, fontSize: 48}}>
                        HD<span style={{...hdFonts.text, fontSize: 44}}>Cast</span>
                    </p>
                    <p style={{...hdFonts.textBold, fontSize: 48}}>
                        {castingDeviceCode}
                    </p>
                </div>
                {/*<TouchableOpacity onClick={() => auth().signOut()} style={{color: "white"}}>*/}
                {/*    Sign Out*/}
                {/*</TouchableOpacity>*/}
            </header>
        </div>
    );
}
