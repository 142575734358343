import React from "react";
import {navigate, Router} from "@reach/router";
import ConsultationPage from "./ConsultationPage";
import GynecologyExamPage from "../GynecologyExamPage";
import {registerNavigateResponder, removeResponderHandler} from "../sessions/messaging";
import PediatricsChildExamPage from "../PediatricsChildExamPage";

export default function ConsultationRouter() {
    const doNavigate = route => {
        switch(route) {
            case "SpecialtyExam:394585009-1":
                return navigate("ginecologia");
            default:
                return navigate("./");
        }
    }

    React.useEffect(() => {
        const rootNavigationResponder = registerNavigateResponder(".*", doNavigate);

        return () => {
            removeResponderHandler(rootNavigationResponder);
        }
    }, []);

    return (
        <Router>
            <ConsultationPage path={"/"}/>
            <GynecologyExamPage path={"ginecologia"}/>
            <PediatricsChildExamPage path={"pediatria-nuevo-bebe"}/>
        </Router>
    )
}
