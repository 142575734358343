import React from "react";
import {sendCastingSessionMessage} from "../sessions/messaging";
import CircularTouchable from "./CircularTouchable";
import {hdFonts} from "../config/fonts";
import {hdColors} from "../config/colors";

export default function HDCastControls(props) {
    const {consultation} = props;

    const doSendKillSessionSignal = () => {
        const message = {
            type: "manageSession",
            action: "killSession"
        }

        sendCastingSessionMessage(message)
            .catch(error => console.warn("[OfficeConsultationPage:CastControls:doSendKillSessionSignal] error sending casting session message", error));
    }

    const doSendEndConsultationSignal = () => {
        const message = {
            type: "manageSession",
            action: "endConsultation"
        }

        sendCastingSessionMessage(message)
            .catch(error => console.warn("[OfficeConsultationPage:CastControls:doSendEndConsultationSignal] error sending casting session message", error));
    }

    const doSendEndVideoCallMessage = () => {
        const message = {
            type: "manageSession",
            action: "endVideoCall"
        }

        sendCastingSessionMessage(message)
            .catch(error => console.warn("[OfficeConsultationPage:CastControls:doSendEndConsultationSignal] error sending casting session message", error));
    }

    const Control = props => (
        <div style={{flex: 1, display: "flex", flexDirection: "column", alignItems: "center", marginTop: 6, marginBottom: 6}}>
            <CircularTouchable onClick={props.onClick} size={54} color={props.color}>
                <i className={`fas fa-${props.icon}`} style={{fontSize: 24, color: "white"}}/>
            </CircularTouchable>
            <span style={{...hdFonts.light, fontSize: 15, color: hdColors.secondaryLightest, marginTop: 6, cursor: "default"}}>{props.label}</span>
        </div>
    )

    return (
        <div style={{display: "flex", flexDirection: "row", alignItems: "center", backgroundColor: hdColors.secondaryDarkest, paddingTop: 6, paddingBottom: 6}}>
            <Control icon={"stop"} color={hdColors.negativeLight} onClick={doSendKillSessionSignal} label={"Terminar Sesión de Cast"}/>
            {consultation.type === "video" && <Control icon={"phone"} color={hdColors.negativeLight} onClick={doSendEndVideoCallMessage} label={"Terminar Videollamada"}/>}
            <Control icon={"check"} color={hdColors.goodDark} onClick={doSendEndConsultationSignal} label={"Terminar Consulta"}/>
        </div>
    )
}
