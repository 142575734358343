import React from "react";
import {hdColors} from "../config/colors";

export default function TouchableOpacity(props) {
    return (
        <div onClick={props.onClick} style={{display: "flex", flexDirection: "column", cursor: "pointer", ...props.style}} className={props.className}>
            {props.children}
        </div>
    )
}
