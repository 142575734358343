import firebase from "firebase";
import {getCurrentSessionRef} from "./device";

const {auth} = firebase;

const base64ToArrayBuffer = str => {
    const binary_string = window.atob(str);
    const len = binary_string.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
}

export async function createChatSessionKeyPair(sessionUserID, sessionID) {
    console.debug("creating chat session key pair");
    const {currentUser} = auth();

    const keyOptions = {
        name: "RSA-OAEP",
        modulusLength: 2048,
        publicExponent: new Uint8Array([1, 0, 1]),
        hash: "SHA-256"
    }

    const keyPair = await crypto.subtle.generateKey(keyOptions, true, ["encrypt", "decrypt"]);

    const publicKey = await crypto.subtle.exportKey("spki", keyPair.publicKey);
    const privateKey = await crypto.subtle.exportKey("pkcs8", keyPair.privateKey);

    const base64PublicKey = btoa(String.fromCharCode.apply(null, new Uint8Array(publicKey)));
    const publicKeyPEM = `-----BEGIN RSA PUBLIC KEY-----\n${base64PublicKey}\n-----END RSA PUBLIC KEY-----`;

    const base64PrivateKey = btoa(String.fromCharCode.apply(null, new Uint8Array(privateKey)));
    localStorage.setItem(`pk-${currentUser.uid}`, base64PrivateKey);
    console.debug("getting keypair session ref");
    const currentSessionRef = await getCurrentSessionRef()
    console.debug("updating current session ref with public key", publicKeyPEM);
    return currentSessionRef
        .update({publicKey: publicKeyPEM})
        .catch(error => console.warn("error updating session public key", error));
}

export async function encryptChatMessage(chatID, message) {
    const chatSecretKey = await getChatSecretKey(chatID);

    const iv = crypto.getRandomValues(new Uint8Array(12));

    const messageBytes = new TextEncoder().encode(message);

    const tagSizeBits = 128;

    const ciphertextBytes = await crypto.subtle.encrypt(
        {
            name: "AES-GCM",
            iv,
            tagLength: tagSizeBits
        },
        chatSecretKey,
        messageBytes
    );

    const ciphertextLength = messageBytes.length;
    const ciphertext = new Uint8Array(ciphertextBytes, 0, ciphertextLength);

    const tag = new Uint8Array(ciphertextBytes.slice(ciphertextBytes.byteLength - ((tagSizeBits + 7) >> 3)));//new Uint8Array(ciphertextBytes, tagStart, tagSizeBits / 8);

    const bufferSize = 4 + iv.length + 4 + tag.length + 4 + ciphertextLength;

    const encryptedMessageBytesBuffer = new Uint8Array(bufferSize);
    encryptedMessageBytesBuffer.set(new Uint8Array([iv.byteLength, 0, 0, 0]))
    encryptedMessageBytesBuffer.set(iv, 4);

    const tagSizeStart = 4 + iv.length;

    encryptedMessageBytesBuffer.set(new Uint8Array([tagSizeBits, 0, 0, 0]), tagSizeStart);
    encryptedMessageBytesBuffer.set(tag, tagSizeStart + 4);

    const ciphertextSizeStart = tagSizeStart + 4 + tag.length;

    encryptedMessageBytesBuffer.set(new Uint8Array([ciphertextLength, 0, 0, 0]), ciphertextSizeStart);
    encryptedMessageBytesBuffer.set(ciphertext, ciphertextSizeStart + 4);

    return btoa(String.fromCharCode.apply(null, encryptedMessageBytesBuffer));
}

export async function decryptChatMessage(chatID, encryptedMessage) {
    const decodedCipherBuffer = new Uint8Array(base64ToArrayBuffer(encryptedMessage));

    const ivLengthBuffer = new Uint8Array(decodedCipherBuffer.slice(0, 1));
    const ivLength = ivLengthBuffer[0];

    if (ivLength < 12 || ivLength >= 16) { // check input parameter
        throw new Error(`invalid iv length: ${ivLength}`);
    }
    const iv = new Uint8Array(decodedCipherBuffer.slice(4, 4 + ivLength));

    const tagSizeStart = 4 + ivLength;
    const tagSizeEnd = tagSizeStart + 4;
    const tagSizeBuffer = new Uint8Array(decodedCipherBuffer.slice(tagSizeStart, tagSizeEnd));
    const tagSize = tagSizeBuffer[0];

    const tagStart = tagSizeEnd;
    const tagEnd = tagStart + (tagSize / 8);
    const tag = new Uint8Array(decodedCipherBuffer.slice(tagStart, tagEnd));

    const ciphertextStart = tagEnd + 4;
    const ciphertext = new Uint8Array(decodedCipherBuffer.slice(ciphertextStart));

    const ciphertextBuffer = new Uint8Array(ciphertext.length + (tagSize / 8));
    ciphertextBuffer.set(ciphertext);
    ciphertextBuffer.set(tag, ciphertext.length);

    const chatSecretKey = await getChatSecretKey(chatID);
    console.debug(`chatID: ${chatID} | chatSecretKey`, chatSecretKey);
    const decryptedBuffer = await crypto.subtle.decrypt(
        {
            name: "AES-GCM",
            iv,
            tagLength: tagSize
        },
        chatSecretKey,
        ciphertextBuffer
    );

    return new TextDecoder().decode(decryptedBuffer);
}

export async function decryptChatSecretKey(chatID, base64EncryptedChatKey) {
    if (!chatID) {
        console.debug("not decrypting chat secret key: no chat ID and/or encrypted chat key");
        return;
    }

    const {currentUser} = auth();

    const base64PrivateKey = localStorage.getItem(`pk-${currentUser.uid}`);
    console.debug("base64PrivateKey", base64PrivateKey);
    const str2ab = str => {
        const buf = new ArrayBuffer(str.length);
        const bufView = new Uint8Array(buf);
        for (let i = 0, strLen = str.length; i < strLen; i++) {
            bufView[i] = str.charCodeAt(i);
        }
        return buf;
    }

    const binaryDerString = window.atob(base64PrivateKey);
    const binaryDer = str2ab(binaryDerString);

    const privateKey = await crypto.subtle.importKey(
        "pkcs8",
        binaryDer,
        {
            name: "RSA-OAEP",
            // Consider using a 4096-bit key for systems that require long-term security
            modulusLength: 2048,
            publicExponent: new Uint8Array([1, 0, 1]),
            hash: "SHA-1",
        },
        true,
        ["decrypt"]
    );
    console.debug("got private key", privateKey);

    const encryptedChatKeyBytes = base64ToArrayBuffer(base64EncryptedChatKey);
    console.debug("decrypting these bytes", encryptedChatKeyBytes);
    const decrypted = await crypto.subtle.decrypt(
        {
            name: "RSA-OAEP",
        },
        privateKey,
        encryptedChatKeyBytes
    );
    console.debug("got decrypted", decrypted);
    const base64DecryptedChatKey = btoa(String.fromCharCode.apply(null, new Uint8Array(decrypted)));

    localStorage.setItem(`ck-${currentUser.uid}-${chatID}`, base64DecryptedChatKey);
}

function getChatSecretKey(chatID) {
    const {currentUser} = auth();

    const base64DecryptedChatKey = localStorage.getItem(`ck-${currentUser.uid}-${chatID}`);
    console.debug(`chatID: ${chatID} | base64DecryptedChatKey`, base64DecryptedChatKey);
    return window.crypto.subtle.importKey(
        "raw",
        base64ToArrayBuffer(base64DecryptedChatKey),
        "AES-GCM",
        true,
        ["encrypt", "decrypt"]
    );
}
