import React from "react";
import printJS from "print-js"
import {mdiClose, mdiDownload, mdiOpenInNew, mdiPrinter} from "@mdi/js";
import Icon from "@mdi/react";
import PDFObject from "pdfobject";

import {
    registerSendAttachmentResponder,
    registerSetDataResponder,
    removeResponderHandler,
    sendCastingSessionMessage
} from "../sessions/messaging";
import {hdFonts} from "../config/fonts";
import {hdColors} from "../config/colors";
import {navigate} from "@reach/router";
import CircularView from "../components/CircularView";

import CircularTouchable from "../components/CircularTouchable";

export const consultationPageHandle = {
    consultationData: {}
}

export default function ExaminationHomeView(props) {
    const {consultationData} = props;
    const {examinationData} = consultationData;
    console.debug("[ExaminationHomeView:RENDER] consultationData", consultationData);

    const attachmentURIRef = React.useRef("");

    const handleSendAttachmentMessage = attachment => {
        attachmentURIRef.current = attachment.uri;

        const attachmentElement = document.getElementById("hd-attachments-container");

        if (!attachmentElement) {
            console.warn("no attachment element found!");
            return;
        }

        switch(attachment.contentType) {
            case "application/pdf":
                attachmentElement.classList.add("active", "animate__animated", "animate__slideInUp");

                attachmentElement.childNodes.forEach(element => {
                    if (element.id !== "pdf-container") return;

                    PDFObject.embed(attachment.uri, element, {pdfOpenParams: {view: "FitV"}});
                });
                break;
            case "image/jpeg":
            case "image/png":
                break;
            case "audio/mpeg":
                break;
            default:
                console.warn(`not handling unsupported attachment contentType "${attachment.contentType}"`);
                return;
        }
    };

    React.useEffect(() => {
        registerSendAttachmentResponder(handleSendAttachmentMessage);

        return () => {
            removeResponderHandler(handleSendAttachmentMessage);
        }
    }, []);

    const ExaminationTextFieldInput = props => {
        const {field, label} = props;

        const examinationFieldValue = examinationData ? examinationData[field] : null;

        const [text, setText] = React.useState(examinationFieldValue || "");

        const setDataTimeoutRef = React.useRef(0);

        const doSetText = text => {
            console.debug("doing set text", text);
            setText(text);

            consultationData[field] = text;
        }

        const handleOnTextChanged = event => {
            const text = event.target.value;

            doSetText(text);

            clearTimeout(setDataTimeoutRef.current);

            const doSetThisData = () => doSetData(text);

            setDataTimeoutRef.current = setTimeout(doSetThisData, 500);
        }

        const doSetData = text => {
            const message = {
                type: "setData",
                field,
                data: text
            }

            sendCastingSessionMessage(message)
                .catch(error => console.warn("[HomePage.js:ExaminationTextFieldInput:doSetData] error sending casting session message", error));
        };

        const doPressAction = event => {
            event.preventDefault();

            const inputEl = document.getElementById(`examination-text-field-input-${field}`);
            console.debug("inputEl", inputEl);
            inputEl.focus();

            const message = {
                type: "doPress",
                pressable: field
            }

            sendCastingSessionMessage(message)
                .catch(error => console.warn("[HomePage.js:ExaminationTextFieldInput:doPressAction] error sending casting session message", error));
        }

        React.useEffect(() => {
            registerSetDataResponder(field, doSetText);

            return () => {
                removeResponderHandler(doSetText);
            }
        }, []);

        return (
            <div style={{width: "100%"}}>
                <div style={{margin: 12, display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
                    <div onClick={doPressAction} style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContents: "space-between",
                        padding: 6,
                        cursor: "pointer",
                        borderTopLeftRadius: 2,
                        borderTopRightRadius: 2,
                        width: "100%"}}>
                        <CircularView size={26} color={hdColors.secondaryDarkest}>
                            <i className={"fas fa-clipboard-list"} style={{fontSize: 13, color: "white"}}/>
                        </CircularView>
                        <span style={{...hdFonts.textLight, fontSize: 20, color: hdColors.secondaryDarkest, marginLeft: 6}}>
                            {label}
                        </span>
                    </div>
                    <div style={{backgroundColor: hdColors.secondaryLightest, alignItems: "center", width: "100%"}}>
                        <textarea
                            id={`examination-text-field-input-${field}`}
                            value={text}
                            onChange={handleOnTextChanged}
                            onSubmit={e => e.preventDefault()}
                            style={{width: "100%", minHeight: "10vh", borderWidth: 0, resize: "none"}}/>
                    </div>
                </div>
            </div>
        )
    }

    const StartGynecologyExam = () => {
        const startGynecologyExam = () => {
            const message = {
                type: "navigate",
                route: "SpecialtyExam:394585009-1"
            }

            navigate("ginecologia").catch(error => console.warn("error navigating to gyno consult", error));

            sendCastingSessionMessage(message)
                .catch(error => console.warn("[HomePage.js:startGynecologyExam] error sending casting session message", error));
        }

        return (
            <div onClick={startGynecologyExam} style={{backgroundColor: hdColors.secondaryLightest, padding: 18, margin: 18, borderRadius: 3, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", cursor: "pointer"}}>
                <CircularView size={96} color={hdColors.secondaryDarkest}>
                    <i className="fas fa-notes-medical" style={{fontSize: 48, color: "white"}}/>
                </CircularView>
                <div style={{...hdFonts.text, fontSize: 14}}>Start Gynecology exam</div>
            </div>
        )
    };

    const StartPediatricsNewBabyExam = () => {
        const startPediatricsNewBabyExam = () => {
            const message = {
                type: "navigate",
                route: "SpecialtyExam:394537008-1"
            }

            const routePrefix = consultationData.type === "office" ? "/consulta-oficina" : "/consulta-video";

            const navigationOptions = {
                state: {
                    consultation: consultationData
                }
            };
            console.log("navigationOptions", navigationOptions);
            navigate(`${routePrefix}/pediatria-nuevo-bebe`, navigationOptions)
                .catch(error => console.warn("error navigating to pediatrics new baby consult", error));

            sendCastingSessionMessage(message)
                .catch(error => console.warn("[HomePage.js:startPediatricsNewBabyExam] error sending casting session message", error));
        }

        return (
            <div onClick={startPediatricsNewBabyExam} style={{backgroundColor: hdColors.secondaryLightest, padding: 18, margin: 18, borderRadius: 3, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", cursor: "pointer"}}>
                <CircularView size={96} color={hdColors.secondaryDarkest}>
                    <i className="fas fa-baby" style={{fontSize: 48, color: "white"}}/>
                </CircularView>
                <div style={{...hdFonts.text, fontSize: 14}}>Iniciar examen de bebés y niños</div>
            </div>
        )
    };

    const AttachmentsContainer = () => {
        const closeAttachmentsContainer = () => {
            const attachmentElement = document.getElementById("hd-attachments-container");

            if (!attachmentElement) {
                console.warn("no attachment element found!");
                return;
            }

            attachmentElement.classList.add("animate__slideOutDown");

            const doClose = () => {
                attachmentElement.classList.remove("active", "animate__animated", "animate__slideInUp", "animate__slideOutDown");
            }

            attachmentElement.addEventListener("animationend", doClose, {once: true});
        }

        const openPrintDialog = () => {
            printJS(attachmentURIRef.current);
        }

        const doDownloadAttachment = () => {
            const xhr = new XMLHttpRequest();
            xhr.responseType = "blob";
            xhr.onload = function(event) {
                const blob = xhr.response;

                const reader = new FileReader();

                reader.addEventListener("load", () => {
                    const tempAnchor = document.createElement("a");
                    tempAnchor.href = reader.result;
                    tempAnchor.download = "download";
                    tempAnchor.target = "_blank";
                    tempAnchor.click();
                });

                reader.readAsDataURL(blob);
            };
            xhr.open("GET", attachmentURIRef.current);
            xhr.send();
        }

        const doOpenAttachment = () => {
            window.open(attachmentURIRef.current);
        }

        const AttachmentsToolbarAction = props => (
            <div style={{display: "flex", flex: 1, flexDirection: "column", alignItems: "center"}}>
                <CircularTouchable onClick={props.onClick}>
                    <Icon path={props.path} size={0.8} color={"white"}/>
                </CircularTouchable>
            </div>
        )

        return (
            <div style={{display: "flex"}}>
                <div className={"hd-attachments-container"} id={"hd-attachments-container"}>
                    <div className={"hd-attachments-toolbar"}>
                        <AttachmentsToolbarAction onClick={openPrintDialog} path={mdiPrinter}/>
                        <AttachmentsToolbarAction onClick={doDownloadAttachment} path={mdiDownload}/>
                        <AttachmentsToolbarAction onClick={doOpenAttachment} path={mdiOpenInNew}/>
                        <AttachmentsToolbarAction onClick={closeAttachmentsContainer} path={mdiClose}/>
                    </div>
                    <div className={"pdf-container"} id={"pdf-container"}/>
                </div>
            </div>
        )
    };

    return (
        <div style={{display: "flex", flex: 1, flexDirection: "column"}}>
            <div style={{flex: 1, display: "flex", flexDirection: "row"}}>
                <div style={{flex: 1, backgroundColor: "white", margin: 12, borderRadius: 12}}>
                    <ExaminationTextFieldInput field={"interpretedReasonForConsultation"} label={"Padecimiento actual"} icon={"fa-user-injured"}/>
                    <ExaminationTextFieldInput field={"diagnosis"} label={"Diagnóstico de la Consulta"}/>
                    <ExaminationTextFieldInput field={"notes"} label={"Notas y Observaciones"}/>
                </div>
            </div>
            <AttachmentsContainer/>
        </div>
    );
}
