import firebase from "firebase";

import config from "../../config";

const {auth} = firebase;

const HOST = config.apiHost;
// const HOST = "https://api.hellodoctor.com.mx";
// const HOST = "http://c70133fbd906.ngrok.io";

class Api {
    static async post(path, data, headers) {
        const userToken = await auth().currentUser.getIdToken();

        headers = headers || {};
        headers["Accept"] = "application/json";
        headers["Content-Type"] = "application/json";
        headers["Authorization"] = userToken;

        const request = fetch(`${HOST}${path}`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(data)
        });

        return request.then(nullSafeJsonResponse)
    }

    static async postNoAuth(path, data, headers) {
        headers = headers || {};
        headers["Accept"] = "application/json";
        headers["Content-Type"] = "application/json";

        const request = fetch(`${HOST}${path}`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(data)
        });

        return request.then(nullSafeJsonResponse)
    }

    static async put(path, data, headers) {
        const userToken = await auth().currentUser.getIdToken();

        headers = headers || {};
        headers["Accept"] = "application/json";
        headers["Content-Type"] = "application/json";
        headers["Authorization"] = userToken;

        const request = fetch(`${HOST}${path}`, {
            method: "PUT",
            headers: headers,
            body: JSON.stringify(data)
        });

        return request.then(nullSafeJsonResponse)
    }

    static async get(path, headers) {
        const userToken = await auth().currentUser.getIdToken();

        headers = headers || {};
        headers["Accept"] = "application/json";
        headers["Authorization"] = userToken;

        const request = fetch(`${HOST}${path}`, {
            method: "GET",
            headers: headers
        });

        return request.then(nullSafeJsonResponse)
    }

    static async getNoAuth(path, headers) {
        headers = headers || {};
        headers["Accept"] = "application/json";
        console.debug("`${HOST}${path}`", `${HOST}${path}`);
        const request = fetch(`${HOST}${path}`, {
            method: "GET",
            headers: headers
        });

        return request.then(nullSafeJsonResponse)
    }

    static async delete(path, headers) {
        const userToken = await auth().currentUser.getIdToken();

        headers = headers || {};
        headers["Authorization"] = userToken;

        return fetch(`${HOST}${path}`, {
            method: "DELETE",
            headers: headers
        });
    }
}

export const nullSafeJsonResponse = response => {
    if (response.status < 200 || response.status >= 300) {
        throw new Error(response.status);
    }

    if (response.headers.get("Content-Length") === "0") {
        return {}
    }

    return response.json()
};

export {HOST};
export default Api;
