import _ from "lodash";
import moment from "moment";
import React from "react";
import {hdFonts} from "../config/fonts";
import {hdColors} from "../config/colors";
import ExaminationHomeView from "../views/ExaminationHomeView";
import CircularView from "../components/CircularView";
import HideableDiv from "../components/HideableDiv";
import ProfilePhoto from "../components/ProfilePhoto";
import HDCastHeader from "../components/HDCastHeader";
import HDCastControls from "../components/HDCastControls";
import {useLocation} from "@reach/router";
import firebase from "firebase";

export const consultationPageHandle = {
    consultationData: {}
}

const {auth, firestore} = firebase;

export default function ConsultationPage() {
    const location = useLocation();
    const {consultationID} = location.state;
    console.debug("CONSULTATION ID", consultationID);
    const [consultation, setConsultation] = React.useState(consultationPageHandle.consultationData);

    const {currentUser} = auth();

    const doGetConsultation = async () => {
        firestore()
            .doc(`consultations/${consultationID}`)
            .get()
            .then(async snapshot => {
                const consultation = snapshot.data();
                consultation.id = snapshot.id;

                const patientSnapshot = await firestore().doc(`users/${currentUser.uid}/patients/${consultation.patient.id}`).get();
                consultation.patient = patientSnapshot.data();
                consultation.patient.id = patientSnapshot.id;

                setConsultation(consultation);
            })
    }

    React.useEffect(() => {
        doGetConsultation().catch(error => console.warn(`error getting consultation data: ${error}`));
    }, []);


    const PatientProfile = () => {
        if (!consultation.patient) {
            return null;
        }

        const {profile} = consultation.patient;

        const formattedAge = profile.birthDate ? moment(profile.birthDate, "YYYY-MM-DD").fromNow(true) : "";

        const ProfileGenderIcon = props => {
            const {gender} = props.profile;

            const getIcon = () => {
                switch(gender) {
                    case "male":
                        return "fa-male";
                    case "female":
                        return "fa-female";
                    case "transgender":
                        return "fa-transgender";
                    default:
                        return "fa-sticker-mule";
                }
            }

            return (
                <i className={`fas ${getIcon()}`} style={{fontSize: props.size, color: props.color}}/>
            )
        }

        return (
            <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: 256,
                backgroundColor: hdColors.secondaryDarkest,
                paddingTop: 18,
                paddingBottom: 18,
                margin: 12,
                borderRadius: 2}}>
                <ProfilePhoto profile={profile} size={84}/>
                <span style={{...hdFonts.textExtraLight, fontSize: 20, color: "white"}}>{profile.displayName}</span>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <span style={{...hdFonts.textBold, fontSize: 16, color: "white", marginRight: 12}}>{formattedAge}</span>
                    <ProfileGenderIcon profile={profile} size={24} color={"white"}/>
                </div>
            </div>
        )
    }

    const ConsultationReasons = () => {
        return (
            <div style={{width: 256, backgroundColor: hdColors.secondaryDarkest, margin: 12, borderRadius: 2}}>
                <div style={{display: "flex", flexDirection: "column", alignItems: "center", padding: 12}}>
                    <CircularView size={42} color={hdColors.secondaryDark}>
                        <i className={`fas fa-user-injured`} style={{fontSize: 16, color: "white"}}/>
                    </CircularView>
                    <span style={{...hdFonts.light, fontSize: 16, color: "white", marginTop: 12}}>{consultation.reasons?.map(r => r.display).join("; ")}</span>
                </div>
            </div>
        )
    }

    return (
        <div style={{display: "flex", flexDirection: "column", justifyContent: "space-between", minHeight: "100vh", flex: 1, backgroundColor: hdColors.secondaryLightest}}>
            <HDCastHeader consultation={consultation}/>
            <div style={{display: "flex", flexDirection: "row"}}>
                <div style={{display: "flex", flexDirection: "column"}}>
                    <PatientProfile/>
                    <ConsultationReasons/>
                </div>
                <HideableDiv isHidden={_.isEmpty(consultation)} style={{display: "flex", flex: 1, backgroundColor: hdColors.secondaryLightest, height: "100%"}}>
                    <ExaminationHomeView consultationData={consultation}/>
                </HideableDiv>
            </div>
            <HDCastControls consultation={consultation}/>
        </div>
    );
}
