import React from "react";
import {Router} from "@reach/router";
import VideoCallPage from "./VideoCallPage";

export default function VideoCallRouter() {
    return (
        <Router>
            <VideoCallPage path={"/"}/>
        </Router>
    )
}
