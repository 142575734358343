import React from "react";
import {hdColors} from "../config/colors";

export default function HideableDiv(props) {
    const {isHidden} = props;

    if (isHidden) {
        return null;
    }

    return (
        <div style={props.style}>
            {props.children}
        </div>
    )
}
