import React from "react";
import {hdColors} from "../config/colors";
import TouchableOpacity from "./TouchableOpacity";

export default function CircularTouchable(props) {
    const {size} = props;

    return (
        <TouchableOpacity onClick={props.onClick} style={{alignItems: "center", justifyContent: "center", height: size, minWidth: size, maxWidth: props.maxWidth, borderRadius: size, backgroundColor: props.color, cursor: "pointer", ...props.style}}>
            {props.children}
        </TouchableOpacity>
    )
}
