// eslint-disable-next-line no-unused-vars
import firebase from "firebase";
import "@fortawesome/fontawesome-free/css/all.css";
import {navigate} from "@reach/router";

import config from "../../config";
import CastService from "../../service";

const {auth, firestore, messaging} = firebase;

const handle = {
    lastMessageSerial: 0,
    castingDeviceData: null,
    isBootstrapped: false,
    isBootstrapping: false,
    bootstrapAttempts: 0,
    activeSession: null,
    listeners: [],
    responders: [],
    unhandledMessages: [],
    queuedMessages: [],
    deviceDataListeners: []
}

export async function bootstrapCastingDevice() {
    if (handle.isBootstrapped) {
        console.log("[device.js:bootstrap] already bootstrapped baby");
        return;
    } else if (handle.isBootstrapping && handle.bootstrapAttempts < 3) {
        console.log("[device.js:bootstrap] mid-bootstrapping, chill will ya?");

        handle.bootstrapAttempts += 1;

        setTimeout(bootstrapCastingDevice, 2000);

        return;
    } else if (handle.bootstrapAttempts >= 3) {
        throw new Error("[device.js:bootstrap] failed to bootstrap despite multiple attempts, no bueno");
    }

    handle.isBootstrapping = true;

    console.debug("handling device registration");
    await handleDeviceRegistration();

    console.debug("registering onMessage handler");
    await registerOnMessageHandler();

    handle.isBootstrapping = false;
    handle.isBootstrapped = true;
}

export async function getCurrentSessionRef() {
    const {currentUser} = auth();

    const {claims: tokenClaims} = await currentUser.getIdTokenResult(true);
    console.debug("got sessionref claims", tokenClaims);

    return firestore().doc(`users/${currentUser.uid}/castingSessions/${tokenClaims.sessionID}`);
}

export async function getCurrentSessionSnapshot() {
    const {currentUser} = auth();

    const {claims: tokenClaims} = await currentUser.getIdTokenResult(true);

    return firestore().doc(`users/${currentUser.uid}/castingSessions/${tokenClaims.sessionID}`).get();
}

async function handleDeviceRegistration() {
    const {currentUser} = auth();

    if (currentUser) {
        console.log(`not registering device: already have user ${currentUser.uid}`);
        return;
    }

    console.log(`registering new device`);

    const messagingToken = await getDeviceMessagingToken();

    if (!messagingToken) {
        console.warn(`[device.js:registerNewDevice] could not register new device: no cloud messaging token available`);
        return;
    }

    const response = await CastService.registerCastingDevice(messagingToken);

    if (!response) {
        // TODO error handling ??
        return;
    }

    const {token} = response.data;

    return auth().signInWithCustomToken(token);
}

async function registerOnMessageHandler() {
    const deviceToken = await getDeviceMessagingToken();

    if (!deviceToken) {
        console.warn("[device.js:registerOnMessageHandler:ERROR] could not register onMessage handler: no device token found");
        return;
    }

    messaging().onMessage(payload => {
        console.log("message received", payload);

        const {data} = payload;

        switch(data.type) {
            case "casting:sessionStarted":
                const {sessionID, sessionUserID} = data;

                CastService.getCastingDeviceSessionToken(sessionID, sessionUserID)
                    .then(response => response.data.token)
                    .then(sessionToken => {
                        console.debug("signing in with session token", sessionToken);
                        auth().signInWithCustomToken(sessionToken);
                    })
                    .catch(error => console.warn("error starting session", error));

                break;

            case "casting:sessionStopped":
                auth().signOut()
                    .then(handleDeviceRegistration)
                    .catch(error => console.warn("error signing out at session stop", error))
                    .finally(() => {
                        navigate("/", {replace: true});

                        window.location.reload();
                    });

                break;
            default:
                console.warn("no handler for message");
        }
    });
}

function notifyDeviceDataListeners(deviceData) {
    console.log(`[devices.js:notifyDeviceDataListeners] notifying listeners about device data`, deviceData);

    handle.deviceDataListeners.forEach(listener => listener(deviceData));

    console.log(`[devices.js:notifyDeviceDataListeners:SUCCESS]`);
}

function getDeviceMessagingToken() {
    const firebaseMessagingOptions = {
        vapidKey: config.firebase.messagingVapidKey
    };

    return messaging()
        .getToken(firebaseMessagingOptions)
        .catch(error => console.warn("error getting token", error));
}
