import React from "react";
import {Router, useNavigate} from "@reach/router";
import HomePage from "./HomePage";
import ConsultationRouter from "./Consultation/router";
import VideoCallRouter from "./VideoCall/router";
import ChatPage from "./Chat/ChatPage";
import firebase from "firebase";
import moment from "moment";

export default function HomeRouter() {
    const auth = firebase.auth();
    const firestore = firebase.firestore();

    const navigate = useNavigate();

    const handleOnAuthStateChanged = async user => {
        if (!user) {
            return navigate("/");
        }

        const userToken = await user.getIdTokenResult(true);
        console.debug("userToken", userToken);

        const {claims} = userToken;

        if (claims.role === "castingDevice") {
            console.info("logged in as default casting device user, going home");
            return navigate("/");
        }

        if (moment().isAfter(claims.sessionExpires)) {
            console.info("session expired, logging out then going home");
            return auth
                .signOut()
                .finally(() => navigate("/"));
        }
        console.debug("GETTING SESSION", `users/${user.uid}/castingSessions/${claims.sessionID}`);
        const sessionSnapshot = await firestore.doc(`users/${user.uid}/castingSessions/${claims.sessionID}`).get();
        const session = sessionSnapshot.data();
        console.debug("got session", session);

        const state = {};

        switch (session.currentNavigationURI) {
            case "chat":
                return navigate("/chat");

            case "chat.id":
                state.chatID = session.currentNavigationParams.chatID;
                return navigate("/chat", {state})

            case "consultation.id":
                state.consultationID = session.currentNavigationParams.consultationID;
                return navigate("/consulta", {state});

            case "consultation.id.video":
                state.consultationID = session.currentNavigationParams.consultationID;
                state.videoRoomSID = session.currentNavigationParams.videoRoomSID;
                return navigate("/video", {state});

            default:
                return navigate("/");
        }
    }

    React.useEffect(() => {
        const authStateListener = auth.onAuthStateChanged(handleOnAuthStateChanged);

        return () => {
            authStateListener();
        }
    }, []);

    return (
        <Router>
            <HomePage path={"/"} />
            <ConsultationRouter path={"consulta/*"}/>
            <VideoCallRouter path={"video/*"}/>
            <ChatPage path={"chat"}/>
        </Router>
    )
}
