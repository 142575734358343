// eslint-disable-next-line no-unused-vars
import firebase from "firebase";
import "@fortawesome/fontawesome-free/css/all.css";
import {getCurrentSessionSnapshot} from "./device";

const handle = {
    lastMessageSerial: 0,
    castingDeviceData: null,
    isBootstrapped: false,
    isBootstrapping: false,
    bootstrapAttempts: 0,
    activeSession: null,
    listeners: [],
    responders: [],
    unhandledMessages: [],
    queuedMessages: [],
    deviceDataListeners: []
}

export function registerDeviceDataListener(listener) {
    const {deviceDataListeners, castingDeviceData} = handle;

    if (!deviceDataListeners.includes(listener)) {
        deviceDataListeners.push(listener);
    }

    if (castingDeviceData) {
        listener(castingDeviceData);
    }
}

export async function sendCastingSessionMessage(message) {
    const {lastMessageSerial} = handle;

    const currentSessionSnapshot = await getCurrentSessionSnapshot();

    if (!currentSessionSnapshot.exists) {
        console.warn("[device.js:sendCastingSessionMessage] could not send casting message: no active session available");
        console.warn("[device.js:sendCastingSessionMessage]", message);

        if (!handle.queuedMessages.includes(message)) {
            handle.queuedMessages.push(message);
        }

        return;
    }

    // TODO actually validate this serial shit
    message.serial = lastMessageSerial + 1;
    message.senderType = "castingDevice";

    await currentSessionSnapshot.ref.collection("messages").add(message);

    console.log("sent casting message", message);

    handle.lastMessageSerial = message.serial;
}

function deliverQueuedMessages() {
    const {queuedMessages} = handle;

    while (queuedMessages.length > 0) {
        const queuedMessage = queuedMessages.shift();

        sendCastingSessionMessage(queuedMessage)
            .then(() => console.debug("[device.js:deliverQueuedMessages] delivered queued message", queuedMessage))
            .catch(error => console.warn("[device.js:deliverQueuedMessages] error sending queued message", error));
    }
}

function handleActiveSessionMessage(message) {
    if (message.senderType === "castingDevice") {
        return;
    }

    if (message.serial < handle.lastMessageSerial) {
        console.debug("skipping handled message", message);
        return;
    }

    if (message.serial > handle.lastMessageSerial + 1) {
        handle.lastMessageSerial = message.serial;

        console.warn("messages are out of order, you fucked up, abort now please");
        return;
    }

    console.debug("handling active session message", message);

    let wasHandled = false;

    switch (message.type) {
        case "handshake":
            break;
        case "navigate":
            wasHandled = handleNavigateMessage(message);
            break;
        case "setData":
            wasHandled = handleSetDataMessage(message);
            break;
        case "doPress":
            wasHandled = handleDoPressMessage(message);
            break;
        case "sendAttachment":
            wasHandled = handleSendAttachmentMessage(message);
            break;
        default:
            console.warn(`[device.js:handleActiveSessionMessage] unrecognized message type "${message.type}"`);
            return;
    }

    if (wasHandled) {
        handle.lastMessageSerial = message.serial;
    } else {
        handle.unhandledMessages.push(message);
    }
}

export function registerNavigateResponder(route, handler) {
    const {responders} = handle;

    if (responders.some(r => r.type === "navigate" && `${r.route}`.match(route))) {
        console.info(`[device.js:registerNavigateResponder] not registering responder: already registered navigate responder for route "${route}`);
        return;
    }

    responders.push({type: "navigate", route, handler});

    retryUnhandledMessages();

    return handle;
}

export function registerSetDataResponder(field, handler) {
    const {responders} = handle;

    if (responders.some(r => r.type === "setData" && r.field === field)) {
        console.info(`[device.js:registerSetDataResponder] not registering responder: already registered setData responder for field "${field}`);
        return;
    }

    console.info(`[device.js:registerSetDataResponder] registering setData responder for field "${field}`);

    responders.push({type: "setData", field, handler});

    retryUnhandledMessages();
}

export function registerDoPressResponder(pressable, handler) {
    const {responders} = handle;

    if (responders.some(r => r.type === "doPress" && r.pressable === pressable)) {
        console.info(`[device.js:registerDoPressResponder] not registering responder: already registered doPress responder for pressable "${pressable}`);
        return;
    }

    console.info(`[device.js:registerDoPressResponder] registering doPress responder for pressable "${pressable}`);

    responders.push({type: "doPress", pressable, handler});
}

export function registerSendAttachmentResponder(handler) {
    const {responders} = handle;

    if (responders.some(r => r.type === "sendAttachment")) {
        console.info(`[device.js:registerSendAttachmentResponder] not registering responder: already registered sendAttachment responder`);
        return false;
    }

    console.info(`[device.js:registerSendAttachmentResponder] registering sendAttachment responder`);

    responders.push({type: "sendAttachment", handler});
}

export function removeResponderHandler(handler) {
    handle.responders = handle.responders.filter(r => r.handler !== handler);
}

function handleNavigateMessage(message) {
    const responder = handle.responders.find(r => r.type === "navigate" && message.route.match(r.route));

    if (!responder) {
        console.warn(`not handling navigate message: no responder found for route "${message.route}`);
        return false;
    }

    console.info(`handling navigate message for route "${message.route}`);

    responder.handler(message.route);

    return true;
}

function handleSetDataMessage(message) {
    handle.responders.filter(r => r.type === "setData" && r.field === message.field).forEach(responder => {
        console.info("handling setData message", message);

        responder.handler(message.data);
    });

    return true;
}

function handleDoPressMessage(message) {
    const responder = handle.responders.filter(r => r.type === "doPress" && r.pressable === message.pressable);

    if (!responder) {
        console.warn(`not handling doPress message: no responder found for pressable "${message.pressable}`);
        return false;
    }

    console.info(`handling doPress message pressable "${message.pressable}`);

    responder.handler(message.data);

    return true;
}

function handleSendAttachmentMessage(message) {
    const responder = handle.responders.find(r => r.type === "sendAttachment");

    if (!responder) {
        console.warn(`not handling sendAttachment message: no responder found`);
        return false;
    }

    console.info(`handling sendAttachment message`);

    responder.handler(message.attachment);

    return true;
}

function retryUnhandledMessages() {
    const {unhandledMessages} = handle;

    unhandledMessages.forEach(handleActiveSessionMessage);
}

function notifyDeviceDataListeners(deviceData) {
    console.log(`[devices.js:notifyDeviceDataListeners] notifying listeners about device data`, deviceData);

    handle.deviceDataListeners.forEach(listener => listener(deviceData));

    console.log(`[devices.js:notifyDeviceDataListeners:SUCCESS]`);
}
