import React from "react";
import HideableDiv from "./HideableDiv";
import {hdFonts} from "../config/fonts";
import {hdColors} from "../config/colors";

export default function ConsultationTypeOverview(props) {
    const {consultation} = props;

    const ConsultationAddress = () => {
        if (!consultation.location) {
            return null;
        }

        const addressParts = consultation.location?.formattedAddress.split(",");
        const firstAddressPart = addressParts.shift();
        const remainingAddress = addressParts.join(",");

        return (
            <HideableDiv isHidden={!consultation.location} style={{display: "flex", flexDirection: "column", alignItems: "center", marginLeft: 12}}>
                <span style={{...hdFonts.text, fontSize: 18, color: hdColors.secondaryDarkest, textAlign: "center", flex: 1}}>{consultation.location?.display}</span>
                <span style={{...hdFonts.light, fontSize: 13, color: hdColors.secondaryDarkest, textAlign: "center"}}>{firstAddressPart}</span>
                <span style={{...hdFonts.light, fontSize: 13, color: hdColors.secondaryDarkest, textAlign: "center"}}>{remainingAddress}</span>
            </HideableDiv>
        )
    }

    return (
        <div style={{flex: 1, display: "flex", flexDirection: "column", paddingLeft: 32, paddingRight: 32}}>
            <ConsultationAddress/>
        </div>
    )
};
