import _ from "lodash";
import moment from "moment";
import React from "react";
import {registerSetDataResponder, removeResponderHandler, sendCastingSessionMessage} from "./sessions/messaging";
import {hdFonts} from "./config/fonts";
import {alpha, hdColors} from "./config/colors";
import CircularView from "./components/CircularView";
import {navigate} from "@reach/router";
import Icon from "@mdi/react"
import {mdiThumbDown, mdiThumbUp, mdiChevronLeft, mdiChevronRight} from "@mdi/js"
import ConsultationTypeOverview from "./components/ConsultationTypeOverview";
import RemoteUserTouchable from "./components/RemoteUserTouchable";
import {consultationPageHandle} from "./Consultation/ConsultationPage";
import CircularTouchable from "./components/CircularTouchable";

export const PEDIATRICS_CHILD_EXAM_ID = "394537008-1";

export default function PediatricsChildExamPage(props) {
    const consultation = React.useState(consultationPageHandle.consultationData);

    const [examHandle, setExamHandle] = React.useState({});
    const [pastExams, setPastExams] = React.useState([]);

    React.useEffect(() => {
        registerSetDataResponder("currentExamHandle", setExamHandle);
        registerSetDataResponder("pastExams", setPastExams);

        const doRequestData = () => {
            const message = {
                type: "requestData",
                field: "pastExams"
            }

            sendCastingSessionMessage(message)
                .catch(error => console.warn("[HomePage.js:OfficeConsultationPage:doRequestData] error sending casting session message", error));

            const examHandleMessage = {
                type: "requestData",
                field: "currentExamHandle"
            }

            sendCastingSessionMessage(examHandleMessage)
                .catch(error => console.warn("[HomePage.js:OfficeConsultationPage:doRequestData] error sending casting session message", error));
        }

        doRequestData()

        return () => {
            removeResponderHandler(setExamHandle);
            removeResponderHandler(setPastExams);
        }
    }, []);

    const ExamHeader = props => {
        const goBack = () => {
            navigate(-1).catch(error => console.warn(`error navigating back: ${error}`));
        }

        const safeGetMoment = time => !time ? moment() : time.toDate ? moment(time.toDate()) : moment(time);

        const consultationStart = safeGetMoment(consultation.scheduledStart);
        const consultationIcon = consultation.type === "video" ? "fa-video" : "fa-building";

        return (
            <div style={{display: "flex", flexDirection: "row", backgroundColor: "white", alignItems: "center", justifyContent: "flex-start", padding: 6, paddingLeft: 18, paddingRight: 18}}>
                <CircularTouchable onClick={goBack} size={42}>
                    <i className={`fas fa-angle-left`} style={{fontSize: 18, color: hdColors.secondaryDarkest}}/>
                </CircularTouchable>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <CircularView size={42} color={hdColors.secondaryDark}>
                        <i className={`fas fa-satellite`} style={{fontSize: 18, color: "white"}}/>
                    </CircularView>
                    <span style={{marginLeft: 12, ...hdFonts.header, fontSize: 28, color: hdColors.secondaryDarkest}}>HD<span style={{...hdFonts.textLight, fontSize: 28}}>Cast</span></span>
                </div>
                <ConsultationTypeOverview consultation={consultation}/>
                <div style={{alignItems: "center", marginRight: 24}}>
                    <CircularView size={36} color={hdColors.secondaryDarkest} maxWidth={48}>
                        <i className={`fas ${consultationIcon}`} style={{fontSize: 18, color: "white"}}/>
                    </CircularView>
                </div>
                <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-end"}}>
                    <span style={{...hdFonts.light, fontSize: 24, color: hdColors.secondaryDarkest}}>{consultationStart.format("HH:mm")}</span>
                    <span style={{...hdFonts.light, fontSize: 15, color: hdColors.secondaryDarkest}}>{consultationStart.format("dddd DD MMMM YYYY")}</span>
                </div>
                <div style={{height: 32, borderWidth: 0, borderRightWidth: 1, borderColor: hdColors.secondaryDarkest, borderStyle: "solid", marginLeft: 24, marginRight: 24}}/>
                <RemoteUserTouchable/>
            </div>
        )
    }

    const doSetPediatricsNewBabyFieldData = (field, data) => {
        const message = {
            type: "setData",
            field: `exam:${PEDIATRICS_CHILD_EXAM_ID}:${field}`,
            data
        }

        sendCastingSessionMessage(message)
            .catch(error => console.warn("[PediatricsNewBabyExamPage.js:doSetPediatricsNewBabyFieldData] error sending casting session message", error));
    }

    const ConceptionDateDateInput = () => {
        const [conceptionDate, setConceptionDate] = React.useState(moment(examHandle.conceptionDate?.toDate()));

        const dateFormat = "DD MMMM YYYY";

        const sanitizedConceptionDate =
            !conceptionDate ? ""
                : conceptionDate.toDate ? moment(conceptionDate.toDate()).format(dateFormat)
                : moment(conceptionDate).format(dateFormat);

        const ClickableDate = props => {
            const {date} = props;

            const doSetConceptionDate = () => {
                examHandle.conceptionDate = moment(date);

                setConceptionDate(examHandle.conceptionDate);

                doSetPediatricsNewBabyFieldData("conceptionDate", examHandle.conceptionDate.toDate());
            }

            const flexBasis = `${1 / 7 * 100}%`;

            const badgeColor = moment(date).format("YYYYMMDD") === conceptionDate.format("YYYYMMDD") ? hdColors.secondaryDarkest : "transparent";
            const color = moment(date).format("YYYYMMDD") === conceptionDate.format("YYYYMMDD") ? "white" : hdColors.secondaryDarkest;

            return (
                <div onClick={doSetConceptionDate} style={{
                    flex: 1,
                    flexBasis,
                    maxWidth: flexBasis,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    cursor: "pointer"}}>
                    <CircularView size={24} color={badgeColor}>
                        <span style={{...hdFonts.light, fontSize: 15, textAlign: "center", color}}>{date.format("D")}</span>
                    </CircularView>
                </div>
            )
        };

        const WeekdayHeader = props => {
            const {weekday} = props;

            const flexBasis = `${1 / 7 * 100}%`;

            return (
                <div style={{flex: 1, flexBasis, display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <CircularView size={24}>
                        <span style={{...hdFonts.textSemiBold, fontSize: 16, textAlign: "center"}}>{weekday.format("ddd")}</span>
                    </CircularView>
                </div>
            )
        }

        const Month = props => {
            const {month} = props;
            const monthDates = _.range(0, month.endOf("month").date()).map(monthDate => moment(month).date(monthDate + 1));
            const weekdays = _.range(0, 7).map(weekday => moment().day(weekday));

            return (
                <div className={`scrollable-month-${month.format("YYYYMM")}`} style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: 304}}>
                    <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
                        <ArrowLeft/>
                        <span style={{...hdFonts.lightBlack, fontSize: 18, color: hdColors.secondaryDarkest}}>{month.format("MMMM YYYY")}</span>
                        <ArrowRight/>
                    </div>
                    <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap"}}>
                        {weekdays.map(w => <WeekdayHeader key={w.format("d")} weekday={w}/>)}
                        {monthDates.map(d => <ClickableDate key={d.format("YYYYMMDD")} date={d}/>)}
                    </div>
                </div>
            )
        }

        const [currentMonth, setCurrentMonth] = React.useState(moment());

        const doScroll = async direction => {
            const multiplier = direction === "right" ? 1 : -1;

            setCurrentMonth(moment(currentMonth).add(multiplier, "months"));
        }

        const ArrowLeft = () => {
            const doScrollLeft = () => doScroll("left");

            return (
                <div onClick={doScrollLeft} style={{cursor: "pointer", marginRight: 24}}>
                    <Icon path={mdiChevronLeft} size={1} color={hdColors.secondaryDarkest}/>
                </div>
            )
        }

        const ArrowRight = () => {
            const doScrollRight = () => doScroll("right");

            return (
                <div onClick={doScrollRight} style={{cursor: "pointer", marginLeft: 24}}>
                    <Icon path={mdiChevronRight} size={1} color={hdColors.secondaryDarkest}/>
                </div>
            )
        }

        return (
            <div style={{backgroundColor: hdColors.secondaryLightest, height: 324, width: 324, borderRadius: 4, display: "flex", justifyContent: "center"}}>
                <div style={{display: "flex", flexDirection: "column", width: "100%", alignItems: "center"}}>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        backgroundColor: hdColors.secondaryDark,
                        width: "100%",
                        paddingTop: 12,
                        paddingBottom: 12}}>
                        <span style={{...hdFonts.textBold, fontSize: 18, color: "white", marginLeft: 6, textAlign: "center"}}>Fecha de concepción</span>
                    </div>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        backgroundColor: hdColors.secondaryLightest,
                        overflow: "hidden",
                        marginLeft: 12, marginRight: 12}}>
                        <span style={{...hdFonts.textBold, fontSize: 24, textAlign: "center", marginTop: 18, marginBottom: 12}}>{sanitizedConceptionDate}</span>
                        <Month month={currentMonth}/>
                    </div>
                </div>
            </div>
        )
    }

    const FieldTextInput = props => {
        const {field} = props;

        const [text, setText] = React.useState(examHandle[field]);

        const handleSubmit = event => {
            event.preventDefault();

            doSetPediatricsNewBabyFieldData(field, examHandle[field]);
        }

        const doSetText = event => {
            const {value: text} = event.target;
            examHandle[field] = text;
            setText(text);
        }

        return (
            <form onSubmit={handleSubmit} style={{flex: 1, display: "flex", flexDirection: "row", justifyContent: "center"}}>
                <input type={"text"} value={text} onChange={doSetText} onBlur={handleSubmit} style={{textAlign: props.textAlign || "center"}}/>
            </form>
        )
    }

    const TimeSeriesInput = props => {
        const {field, label, icon} = props;

        const data = pastExams.map(exam => exam[field]);

        const Data = props => {
            const {data} = props;

            const formattedData = data ? parseFloat(`${data}`).toFixed(2) : "-";

            return (
                <div style={{flex: 1, minWidth: "20%", maxWidth: "20%", display: "flex", justifyContent: "center", height: 32, maxHeight: 32}}>
                    <span style={{...hdFonts.text, fontSize: 14}}>{formattedData}</span>
                </div>
            )
        }

        return (
            <div style={{display: "flex", flexDirection: "row", width: "100%"}}>
                {data.map(d => <Data data={d}/>)}
                <div style={{flex: 1, maxWidth: "20%", display: "flex", flexDirection: "row", height: 32}}>
                    <FieldTextInput field={field}/>
                </div>
            </div>
        )
    }

    const TimeSeriesInputs = () => {
        const pastLabels = pastExams.map(exam => moment(exam.time.toDate()).format("DD MMM"));
        const labels = [...pastLabels, "Hoy"];

        const SeriesLabel = props => (
            <div style={{flex: 1, minWidth: "20%", maxWidth: "20%", display: "flex", flexDirection: "column", alignItems: "center"}}>
                <span style={{...hdFonts.textSemiBold, fontSize: 14, textAlign: "center"}}>{props.label}</span>
            </div>
        )

        const FieldLabel = props => (
            <span style={{...hdFonts.textSemiBold, fontSize: 16, color: props.color || hdColors.secondaryDarkest, height: 32}}>{props.label}</span>
        )

        return (
            <div style={{display: "flex", flexDirection: "row", marginBottom: 0, padding: 12}}>
                <div style={{display: "flex", flexDirection: "column", width: 196}}>
                    <FieldLabel label={"Fecha de visita"} color={hdColors.secondaryDark}/>
                    <FieldLabel label={"Peso (kg)"}/>
                    <FieldLabel label={"Talla/Estatura (cm)"}/>
                    <FieldLabel label={"Perímetro cefálico (cm)"}/>
                </div>
                <div style={{flex: 1, display: "flex", flexDirection: "column", alignItems: "flex-start", marginLeft: 12, maxWidth: "100%", overflow: "scroll"}}>
                    <div style={{display: "flex", flexDirection: "row", alignItems: "center", height: 32, width: "100%"}}>
                        {labels.map(l => <SeriesLabel label={l}/>)}
                    </div>
                    <TimeSeriesInput field={"weight"} label={"Peso"} icon={"fa-weight"}/>
                    <TimeSeriesInput field={"height"} label={"Talla/Estatura"} icon={"fa-baby"}/>
                    <TimeSeriesInput field={"headPerimeter"} label={"Perímetro cefálico"} icon={"fa-baby"}/>
                </div>
            </div>
        )
    }

    const TextFieldItem = props => {
        const {field, label} = props;

        const [isEditing, setIsEditing] = React.useState(false);

        const ItemTextInput = props => {
            const {field} = props;

            const [text, setText] = React.useState(examHandle[field]);

            const doSetIsEditing = isEditing => {
                if (isEditing) {
                    setTimeout(() => document.getElementById(`tamiz-${props.field}-notes-textarea`).focus(), 150);
                }

                setIsEditing(isEditing);
            }
            const submitTimeoutRef = React.useRef(0);

            const handleSubmit = () => {
                doSetPediatricsNewBabyFieldData(`tamiz.${props.field}.notes`, examHandle[props.field]);
            }

            const doSetText = event => {
                const {value: text} = event.target;
                examHandle[field] = text;
                setText(text);

                clearTimeout(submitTimeoutRef.current);

                submitTimeoutRef.current = setTimeout(handleSubmit, 500);
            }

            const AddObservationIcon = () => !isEditing && _.isEmpty(text) && (
                <CircularView color={hdColors.secondaryDarkest} size={48} style={{marginTop: 24}}>
                    <i className={"fas fa-plus"} style={{fontSize: 18, color: "white"}}/>
                </CircularView>
            );

            return (
                <div onClick={() => !isEditing && doSetIsEditing(true)} style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    width: "100%",
                    cursor: "pointer",
                    alignItems: "center"}}>
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        flex: 1,
                        width: "100%",
                        // borderWidth: 1, borderStyle: "solid", borderColor: hdColors.secondaryLightest,
                        borderTopWidth: 0,
                        borderRadius: 3,
                        paddingTop: 6,
                        justifyContent: "center",
                        backgroundColor: "white"}}>
                        {isEditing && <textarea id={`tamiz-${props.field}-notes-textarea`} rows={6} value={text} onChange={doSetText} onBlur={() => doSetIsEditing(false)} style={{
                            resize: "none",
                            textAlign: props.textAlign || "center",
                            borderWidth: 0,
                            width: "100%"
                        }}/>}
                        {!isEditing && <span style={{textAlign: props.textAlign || "center", width: "100%", ...hdFonts.text, fontSize: 17}}>{text}</span>}
                    </div>
                    <AddObservationIcon/>
                </div>
            )
        }
        console.log("RENDER", examHandle[field]);
        return (
            <div style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                backgroundColor: "white",
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: _.isEmpty(examHandle[field]) ? "white" : hdColors.good,
                borderRadius: 3,
                margin: 12,
                marginBottom: 24,
                padding: 24,
                paddingTop: 6}}>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center", padding: 6}}>
                    <span style={{flex: 1, ...hdFonts.textSemiBold, fontSize: 20, color: hdColors.secondaryDarkest}}>{label}</span>
                </div>
                <div style={{display: "flex", flex: 1, width: "100%"}}>
                    <ItemTextInput field={field} textAlign={"left"}/>
                </div>
            </div>
        )
    }

    const TextFieldContainer = props => (
        <div style={{flex: 1, display: "flex", flexDirection: "column", backgroundColor: hdColors.secondaryLightest, margin: 6}}>
            {/*<div style={{display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: hdColors.secondaryDark, paddingTop: 24}}/>*/}
            <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "center", padding: 12}}>
                <TextFieldItem field={props.field} label={props.label}/>
            </div>
        </div>
    )

    const TamizSection = () => {
        let {tamiz: tamizHandle} = examHandle;

        if (!tamizHandle) {
            tamizHandle = {};
            examHandle.tamiz = tamizHandle;
        }

        const TamizItem = props => {
            const {field, label} = props;

            let fieldHandle = tamizHandle[field];

            if (!fieldHandle) {
                fieldHandle = {
                    isNormal: null,
                    notes: ""
                }

                tamizHandle[field] = fieldHandle;
            }

            const [isNormal, setIsNormal] = React.useState(tamizHandle[field].isNormal);
            const [notes, setNotes] = React.useState(tamizHandle[field].notes);

            const IsNormalOptionTouchable = props => {
                const {option} = props;

                const isCurrentOption = option === isNormal;
                const backgroundColor = isCurrentOption ?
                    isNormal ? hdColors.goodDark : hdColors.negativeLight
                    : alpha(hdColors.secondaryLightest, 0.5);

                const color = isCurrentOption ? "white" : hdColors.secondaryDarkest;
                const opacity = isCurrentOption ? 1 : fieldHandle.isNormal === null ? 1 : 0.4;

                const doSelectCurrentOption = () => {
                    tamizHandle[field].isNormal = option;

                    setIsNormal(tamizHandle[field].isNormal);

                    doSetPediatricsNewBabyFieldData(`tamiz.${field}.isNormal`, tamizHandle[field].isNormal);
                }

                const iconName = option === true ? mdiThumbUp : mdiThumbDown;
                const iconTitle = option === true ? "Normal" : "No normal";

                return (
                    <div onClick={doSelectCurrentOption} style={{display: "flex", alignItems: "center", opacity, cursor: "pointer", marginLeft: 24}}>
                        <CircularView size={48} color={backgroundColor} >
                            <Icon path={iconName} title={iconTitle} size={1} color={color}/>
                        </CircularView>
                    </div>
                )
            }

            const TamizNotesTextInput = props => {
                const {field} = props;

                const [text, setText] = React.useState(tamizHandle[field].notes);
                const [isEditing, setIsEditing] = React.useState(false);

                const doSetIsEditing = isEditing => {
                    if (isEditing) {
                        setTimeout(() => document.getElementById(`tamiz-${props.field}-notes-textarea`).focus(), 150);
                    }

                    setIsEditing(isEditing);
                }
                const submitTimeoutRef = React.useRef(0);

                const handleSubmit = () => {
                    doSetPediatricsNewBabyFieldData(`tamiz.${props.field}.notes`, tamizHandle[props.field].notes);
                }

                const doSetText = event => {
                    const {value: text} = event.target;
                    tamizHandle[field].notes = text;
                    setText(text);

                    clearTimeout(submitTimeoutRef.current);

                    submitTimeoutRef.current = setTimeout(handleSubmit, 500);
                }

                const AddObservationIcon = () => !isEditing && _.isEmpty(text) && (
                    <CircularView color={hdColors.secondaryDarkest} size={48} style={{marginTop: 24}}>
                        <i className={"fas fa-plus"} style={{fontSize: 18, color: "white"}}/>
                    </CircularView>
                );

                return (
                    <div onClick={() => !isEditing && doSetIsEditing(true)} style={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                        width: "100%",
                        cursor: "pointer",
                        alignItems: "center"}}>
                        <span style={{...hdFonts.text, fontSize: 15, color: hdColors.secondaryDarkest, textAlign: "center"}}>Observaciones</span>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            flex: 1,
                            width: "100%",
                            // borderWidth: 1, borderStyle: "solid", borderColor: hdColors.secondaryLightest,
                            borderTopWidth: 0,
                            borderRadius: 3,
                            paddingTop: 6,
                            justifyContent: "center",
                            backgroundColor: "white"}}>
                            {isEditing && <textarea id={`tamiz-${props.field}-notes-textarea`} rows={6} value={text} onChange={doSetText} onBlur={() => doSetIsEditing(false)} style={{
                                resize: "none",
                                textAlign: props.textAlign || "center",
                                borderWidth: 0,
                                width: "100%"
                            }}/>}
                            {!isEditing && <span style={{textAlign: props.textAlign || "center", width: "100%", ...hdFonts.text, fontSize: 17}}>{text}</span>}
                        </div>
                        <AddObservationIcon/>
                    </div>
                )
            }

            return (
                <div style={{
                    flex: 1,
                    flexBasis: "40%",
                    display: "flex",
                    maxWidth: "40%",
                    flexDirection: "column",
                    backgroundColor: "white",
                    borderWidth: 1,
                    borderStyle: "solid",
                    borderColor: isNormal !== null ? isNormal ? hdColors.good : hdColors.negativeLight : "white",
                    borderRadius: 3,
                    margin: 12,
                    marginBottom: 24,
                    padding: 24,
                    paddingTop: 6}}>
                    <div style={{display: "flex", flexDirection: "row", alignItems: "center", padding: 6}}>
                        <span style={{flex: 1, ...hdFonts.textSemiBold, fontSize: 20, color: hdColors.secondaryDarkest}}>{label}</span>
                        <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-around"}}>
                            <IsNormalOptionTouchable option={true}/>
                            <IsNormalOptionTouchable option={false}/>
                        </div>
                    </div>
                    <div style={{display: "flex", flex: 1, width: "100%"}}>
                        <TamizNotesTextInput field={field} textAlign={"left"}/>
                    </div>
                </div>
            )
        }

        return (
            <div style={{display: "flex", flexDirection: "column", backgroundColor: hdColors.secondaryLightest}}>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    backgroundColor: hdColors.secondaryDark,
                    width: "100%",
                    paddingTop: 12,
                    paddingBottom: 12,
                    borderRadius: 48}}>
                    <span style={{...hdFonts.light, fontSize: 32, color: "white", marginLeft: 6, textAlign: "center"}}>Tamiz</span>
                </div>
                <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "center", padding: 12}}>
                    <TamizItem field={"metabolic"} label={"Tamiz Metabólico"}/>
                    <TamizItem field={"auditory"} label={"Tamiz Auditivo"}/>
                    <TamizItem field={"cardiological"} label={"Tamiz Cardiológico"}/>
                    <TamizItem field={"hip"} label={"Tamiz de Cadera"}/>
                    <TamizItem field={"ophthalmological"} label={"Tamiz Oftamológico"}/>
                </div>
            </div>
        )
    }

    const HaizeaLevantSection = () => {
        let {haizeaLevant: haizeaLevantHandle} = examHandle;

        if (!haizeaLevantHandle) {
            haizeaLevantHandle = {};
            examHandle.haizeaLevant = haizeaLevantHandle;
        }

        const getItemKey = item => `${item.section}:${item.display}`;

        const HaizeaLevantItem = props => {
            const {section, display} = props;

            const itemKey = `${section}:${display}`;

            const [value, setValue] = React.useState(haizeaLevantHandle[display]);

            const handleSubmit = event => {
                event.preventDefault();

                console.debug(`SUBMITTING field: haizeaLevant[${display}]`, haizeaLevantHandle[display]);
                doSetPediatricsNewBabyFieldData(`haizeaLevant.${display}`, haizeaLevantHandle[display]);
            }

            const doSetValue = event => {
                const {value} = event.target;
                haizeaLevantHandle[display] = value;
                setValue(value);
            }

            return (
                <div style={{display: "flex", flexDirection: "row", alignItems: "center", flex: 1, flexBasis: "30%", maxWidth: "30%", margin: 6, padding: 6, backgroundColor: "white", borderRadius: 2}}>
                    <span style={{flex: 1, ...hdFonts.text, fontSize: 18, color: hdColors.secondaryDarkest}}>{display}</span>
                    <form onSubmit={handleSubmit} onBlur={handleSubmit} style={{
                        flexDirection: "column",
                        borderBottomColor: hdColors.primary,
                        borderBottomWidth: 1,
                        borderBottomStyle: "solid",
                        width: 54,
                        height: "70%",
                        margin: 4,
                        padding: 6,
                        borderRadius: 2
                    }}>
                        <input type={"text"} value={value} onChange={doSetValue} style={{textAlign: "center", borderWidth: 0, width: "100%", height: "100%"}}/>
                    </form>
                </div>
            )
        }

        const socializationItems = [
            {section: "Socialización", display: "Reacciona a la voz"},
            {section: "Socialización", display: "Distingue a su madre"},
            {section: "Socialización", display: "Reconoce el biberón"},
            {section: "Socialización", display: "Mira sus manos"},
            {section: "Socialización", display: "Persecucioón óptica vertical"},
            {section: "Socialización", display: "Persecucioón óptica horizontal"},
            {section: "Socialización", display: "Irritabilidad permanente"},
            {section: "Socialización", display: "Sobresalto exagerado"},
            {section: "Socialización", display: "Pasividad espresiva"},
            {section: "Socialización", display: "Busca objeto caido"},
        ];

        const languageAndLogicItems = [
            {section: "Lenguaje y lógica matemática", display: "Atiende conversación"},
            {section: "Lenguaje y lógica matemática", display: "Rie a carcajadas"},
            {section: "Lenguaje y lógica matemática", display: "Balbucea"},
            {section: "Lenguaje y lógica matemática", display: `"Dice "mamá, papá"`},
            {section: "Lenguaje y lógica matemática", display: "Copmrende una porhibición"},
            {section: "Lenguaje y lógica matemática", display: "Reconoce su nombre"},
            {section: "Lenguaje y lógica matemática", display: "Comprende signif de palabras"},
            {section: "Lenguaje y lógica matemática", display: "Obedece orden por gestos"},
            {section: "Lenguaje y lógica matemática", display: "Mamá/Papá"},
            {section: "Lenguaje y lógica matemática", display: `Utiliza la palabra "NO"`},
        ];

        const manipulationItems = [
            {section: "Manipulación", display: "Junta manos"},
            {section: "Manipulación", display: "Dirige la mano al objeto"},
            {section: "Manipulación", display: "Cambia objetos de mano"},
            {section: "Manipulación", display: "Se quita el pañuelo de la cara"},
            {section: "Manipulación", display: "Realiza pinza inferior"},
            {section: "Manipulación", display: "Realiza pinza superior"},
            {section: "Manipulación", display: "Señala con el indice"},
            {section: "Manipulación", display: "Gabatea espontáneamente"},
            {section: "Manipulación", display: "Pasa páginas"},
            {section: "Manipulación", display: "Hace torre de 2 cubos"},
        ];

        return (
            <div style={{display: "flex", flexDirection: "column", backgroundColor: hdColors.secondaryLightest}}>
                <div style={{display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: hdColors.secondaryDark, width: "100%", paddingTop: 12, paddingBottom: 12, borderRadius: 48}}>
                    <span style={{...hdFonts.textBold, fontSize: 18, color: "white", textAlign: "center"}}>Tabla de desarrollo Haizea-Levant</span>
                </div>
                <div style={{display: "flex", flexDirection: "column", padding: 12, alignItems: "center"}}>
                    <div style={{display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: hdColors.secondaryDark, paddingTop: 4, paddingBottom: 4, paddingLeft: 12, paddingRight: 12, borderRadius: 24}}>
                        <span style={{...hdFonts.textBold, fontSize: 17, color: "white", textAlign: "center"}}>Socialización</span>
                    </div>
                    <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap", padding: 12}}>
                        {socializationItems.map(i => <HaizeaLevantItem key={getItemKey(i)} section={i.section} display={i.display}/>)}
                    </div>

                    <div style={{display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: hdColors.secondaryDark, paddingTop: 4, paddingBottom: 4, paddingLeft: 12, paddingRight: 12, borderRadius: 24}}>
                        <span style={{...hdFonts.textBold, fontSize: 17, color: "white", textAlign: "center"}}>Lenguaje y lógica matemática</span>
                    </div>
                    <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap", padding: 12}}>
                        {languageAndLogicItems.map(i => <HaizeaLevantItem key={getItemKey(i)} section={i.section} display={i.display}/>)}
                    </div>

                    <div style={{display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: hdColors.secondaryDark, paddingTop: 4, paddingBottom: 4, paddingLeft: 12, paddingRight: 12, borderRadius: 24}}>
                        <span style={{...hdFonts.textBold, fontSize: 17, color: "white", textAlign: "center"}}>Manipulación</span>
                    </div>
                    <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap", padding: 12}}>
                        {manipulationItems.map(i => <HaizeaLevantItem key={getItemKey(i)} section={i.section} display={i.display}/>)}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div style={{flex: 1, display: "flex", flexDirection: "column", minHeight: "100vh", backgroundColor: hdColors.secondaryLightest, paddingBottom: 128, maxWidth: "100%", overflow: "hidden"}}>
            <ExamHeader/>
            <div style={{display: "flex", flexDirection: "row", alignItems: "center", padding: 12, paddingLeft: 64, paddingRight: 64}}>
                {/*<ConceptionDateDateInput/>*/}
                <div style={{flex: 1, display: "flex", flexDirection: "column", backgroundColor: hdColors.secondaryLightest, borderRadius: 2, margin: 12}}>
                    <div style={{display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: hdColors.secondaryDark, width: "100%", paddingTop: 12, paddingBottom: 12, borderRadius: 48}}>
                        <span style={{...hdFonts.textBold, fontSize: 18, color: "white", marginLeft: 6, textAlign: "center"}}>Exploración Pediátrica</span>
                    </div>
                    <div style={{flex: 1, display: "flex", flexDirection: "column", width: "100%"}}>
                        <TimeSeriesInputs/>
                    </div>
                </div>
            </div>
            <div style={{flex: 1, display: "flex", flexDirection: "row", paddingLeft: 64, paddingRight: 64}}>
                {/*<div style={{flex: 1, display: "flex", flexDirection: "column", backgroundColor: hdColors.secondaryLightest, borderRadius: 2, margin: 12, marginLeft: 64, marginRight: 64}}>*/}
                {/*</div>*/}
                <TextFieldContainer field={"nutrition"} label={"Alimentación"}/>
                <TextFieldContainer field={"neurologicalDevelopment"} label={"Neurodesarrollo"}/>
                <TextFieldContainer field={"sleep"} label={"Sueño"}/>
            </div>
            <div style={{flex: 1, display: "flex", flexDirection: "column", backgroundColor: hdColors.secondaryLightest, borderRadius: 2, margin: 12, marginLeft: 64, marginRight: 64}}>
                <TamizSection/>
            </div>
            <div style={{flex: 1, display: "flex", flexDirection: "column", backgroundColor: hdColors.secondaryLightest, borderRadius: 2, margin: 12, marginLeft: 64, marginRight: 64}}>
                <HaizeaLevantSection/>
            </div>
        </div>
    );
}
