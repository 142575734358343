import React from "react";
import {hdColors} from "../config/colors";

export default function CircularView(props) {
    const {size} = props;

    return (
        <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: size, minWidth: size, maxWidth: props.maxWidth, borderRadius: size, backgroundColor: props.color, ...props.style}}>
            {props.children}
        </div>
    )
}
