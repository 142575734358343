import {bootstrapCastingDevice} from "./app/sessions/device";

import firebase from "firebase";
// These imports load individual services into the firebase namespace.
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/messaging";

import config from "./config";

export function bootstrapFirebase() {
    // Initialize Firebase
    const firebaseConfig = {
        apiKey: config.firebase.apiKey,
        authDomain: config.firebase.authDomain,
        databaseURL: config.firebase.databaseURL,
        projectId: config.firebase.projectId,
        storageBucket: config.firebase.storageBucket,
        messagingSenderId: config.firebase.messagingSenderId,
        appId: config.firebase.appId,
        measurementId: config.firebase.measurementId
    };

    firebase.initializeApp(firebaseConfig);

    bootstrapCastingDevice().catch(error => console.warn(`error occurred during bootstrapping: ${error}`));
}
