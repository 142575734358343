import React from "react";
import moment from "moment";
import CircularView from "./CircularView";
import {hdColors} from "../config/colors";
import {hdFonts} from "../config/fonts";
import ConsultationTypeOverview from "./ConsultationTypeOverview";
import RemoteUserTouchable from "./RemoteUserTouchable";

export default function HDCastHeader(props) {
    return (
        <div style={{display: "flex", flexDirection: "row", backgroundColor: "white", alignItems: "center", justifyContent: "flex-start", padding: 6, paddingLeft: 18, paddingRight: 18}}>
            <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <CircularView size={42} color={hdColors.secondaryDark}>
                    <i className={`fas fa-satellite`} style={{fontSize: 18, color: "white"}}/>
                </CircularView>
                <span style={{marginLeft: 12, ...hdFonts.header, fontSize: 28, color: hdColors.secondaryDarkest}}>HD<span style={{...hdFonts.textLight, fontSize: 28}}>Cast</span></span>
            </div>
            {props.children}
            <div style={{height: 32, borderWidth: 0, borderRightWidth: 1, borderColor: hdColors.secondaryDarkest, borderStyle: "solid", marginLeft: 24, marginRight: 24}}/>
            <RemoteUserTouchable/>
        </div>
    )
}
